.breadcomp {
    display: flex;
    font-size: 13px;
    font-weight: 500;
    cursor: pointer;
    color: $paragraph-color;
    margin-bottom: 10px;

    .breadcomp-arrow {
        padding: 0px 1px;
        font-weight: 800;
    }

    .breadcomp-product-title {
        max-width: 100px;
        white-space: nowrap;
        @media only screen and (max-width: 991px) {
            overflow: hidden;
        }
        text-overflow: ellipsis;
        display: inline-block;
        vertical-align: middle;


    }

    .breadcomp-product-titles:hover {
        color: $primary_color;
    }
}