.navigation {
  width: 100%;
  background: $nav-bg;
  display: flex;
  color: $nav-text-color;
  justify-content: space-between;
  padding:  5px 0px 5px 20px;
  font-size: 14px;
  position: sticky;
  top: 76px;
  z-index: 5;

  .navigation-menu-item {
    margin-right: 20px;
  }

  .subscrib-button {
    cursor: pointer;
    width: fit-content;
    padding: 0px 15px;
    background: $primary_color;
    color: $white;
    border-radius: 5px;
    font-size: 14px;
    font-weight: 500;
    margin-right: 94px;
  }
}

.sidebar-top {
  display: flex;
  position: absolute;
  justify-content: space-between;
  width: 100%;
  padding: 15px;
  background: $header-bg;
  color: $header-text-color;

  .sidebar-topleft {
    display: flex;

    .topleft-icon {
      color: $header-text-color;
      font-weight: 700;
      padding: 2px;
      cursor: pointer;
      margin-right: 10px;

      .close-icon-sidebar {
        color: $header-text-color !important;
      }
    }
  }

  .sidebar-topright {
    // background: $primary_color;
    border: 2px solid $primary_color;
    border-radius: 24px;
    color: $header-text-color;
    font-size: 16px;
    font-weight: 700;
    text-align: center;
    cursor: pointer;
    padding: 3px;
    width: 25%;
  }

  .sidebar-topright-usericon {
    border-radius: 50%;
    cursor: pointer;
    padding: 3px;
    margin-top: -4px;
    background: #ff7e00;
  }

}

.dropdown-menu.show {
  inset: 6px auto auto -14px !important;
}

.title {
  font-size: $s-size0;
  color: $header-text-color;
  font-weight: 700;
  padding: 4px;
}

.sidebar-icons {
  margin-bottom: 6px;
  font-size: 18px;
  color: $primary_color !important;
}

// .botm-tabs{
//   position: sticky !important;
//   bottom: 0 !important;
//   z-index: 10 !important;

  
// }
