
.Quality-product-row2-col-products {

    margin: none !important;
    // margin-top: 10px;
    margin-bottom: 10px;
    // padding: 10px;
    margin: 10px;
    cursor: pointer;

    .Quality-product-row2-col-image {
        img{
            aspect-ratio: $app-aspect-ratio;
            width: 100%;
            // height: 100%;
            // max-height: 130px;
            object-fit: contain;
        }

        .Quality-product-row2-col-image-details {
            // height: 150px;
            // width: 150px;
            align-items: center;
            justify-content: center;
        }
    }

    .Quality-product-row2-col-image-details {
        display: inline;
        text-align: center;

        .Quality-product-row2-col-image-product {
            color: $primary_color;
            font-size: 14px;
            font-weight: 500;
            display: flex;
            justify-content: start;
            text-align: start;
            padding-left: 10px;
            padding-top: 10px;
        }

        .Quality-product-row2-col-image-product-name {
            -webkit-line-clamp: 3;
            -webkit-box-orient: vertical;
            color: $paragraph-color;
            display: -webkit-box;
            font-family: $font-family-2;
            font-size: 14px;
            font-weight: 400;
            height: 50px;
            margin-top: 10px;
            margin-bottom: 10px;
            overflow: hidden;
            padding: 0 10px;
            text-overflow: ellipsis;
            display: flex;
            justify-content: start;
            text-align: start;




          
            // margin-top: 10px;
     
            // font-weight: 400;
            // color: $paragraph-color;
            // font-family: $font-family-2;
            // display: flex;
            // justify-content: start;
            // text-align: start;
            // display: -webkit-box;
           
            // -webkit-box-orient: vertical;
            // overflow: hidden;
            // text-overflow: ellipsis;
            // height: 60px;
        }
    }
}

.Quality-product-row2-col-products:hover {
    transition: all .3s ease-in-out;
    box-shadow: 0px 10px 20px 0px rgba(0, 0, 0, .1);
}


.Quality-products {
    @media only screen and (min-width: 576px) {
        width: 300px;
    }

    display: flex;
    // padding-top: 10px;
    flex-direction: row;

    .Quality-products-details-left1 {
        background: $primary_color;
        width: 82px;
        margin-top: 12px;
        height: 2px;
      

        @media only screen and (max-width: 576px) {
            width: 25px;
            height: 2.5px;
            margin-top: 10px;
        }

        .Quality-products-details-left1-line {
            width: 100px;
            color: $primary_color;

        }

    }

    .Quality-products-details-left2 {
        color: $primary_color;
        font-size: 16px;
        font-weight: 500;
        padding-top: 0px;
        padding-left: 5px;
        width: 100%;

        @media only screen and (max-width: 576px) {
            font-size: $s-size;
        }
    }
}


.toprated-row {
    padding-top: 20px;
    width: 100%;

    .toprated-column {




        .toprated-products {

            .toprated-products-types {
                font-size: 25px;
                font-weight: 700;
            }
        }
    }
}

.slidebar-hedad {
    display: flex;
    width: 100%;
    margin: 20px;

    .slidebar-sub1 {
        .slidebar-sub1-image {
            width: 100%;
            height: 70px;

        }

        padding-right: 10px;
    }

    .slidebar-sub2 {
        .slidebar-sub2-name {
            font-size: 16px;
            color: gray;
        }

        .slidebar-sub2-price {
            font-size: 16px;
            font-weight: 700;
        }
    }
}

.slider-div {
    display: flex;

    .slider-head {
        flex-grow: 1;
        font-size: 25px;
        font-weight: 700;
    }

    .slider-arrow {
        display: flex;
        float: right;
        padding-top: 10px;
        padding-right: 30px;

        .previous {
            border: 2px solid gray;
            color: gray;
            border-radius: 100%;
            width: 25px;
            height: 25px;
            display: flex;
            align-items: center;
            justify-content: center;
        }

        .next {

            border: 2px solid gray;
            color: gray;
            border-radius: 100%;
            width: 25px;
            height: 25px;
            display: flex;
            align-items: center;
            justify-content: center;
            margin-left: 5px;


        }
    }
}

.next1 {
    // border: 2px solid gray;
    color: gray;
    border-radius: 100%;
    width: 25px;
    height: 25px;
    display: flex;
    align-items: center;
    justify-content: center;
    margin-left: 5px;

}

.previous1 {
    // border: 2px solid gray;
    color: gray;
    border-radius: 100%;
    width: 25px;
    height: 25px;
    display: flex;
    align-items: center;
    justify-content: center;
}

.previous2 {
    border: 2px solid gray;
    color: gray;
    border-radius: 100%;
    width: 25px;
    height: 25px;
    display: flex;
    align-items: center;
    justify-content: center;
}

.next2 {
    border: 2px solid gray;
    color: gray;
    border-radius: 100%;
    width: 25px;
    height: 25px;
    display: flex;
    align-items: center;
    justify-content: center;
    margin-left: 5px;

}