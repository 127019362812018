.place-row {
    height: 100vh;
    position: relative;
    background-color: white;
    z-index: 44;
    overflow: hidden;
    border-radius: 5px;
    z-index: -1;

    .place {
        .placeholder0 {
            width: 80px;
            height: 350px;
            background: $gray-20;
            margin-top: 50px;
            margin-right: 10px;
        }

        .placeholder {
            width: 100%;
            height: 392px;
            background: $gray-20;
            margin-top: 10px;
            display: flex;
            justify-content: center;
            align-items: center;
        }
    }

    @media screen and (min-width: 991px) {
        .place1 {

            // display: flex;
            // justify-content: space-evenly;
            // margin-top: 30px;
            // margin-left: 60px;
            // width: 100%;
            // margin-right: 50px !important;
            .placeholder0 {
                width: 50%;
                height: 50px;
                background: $gray-20;
                border-radius: 5px;
                margin-right: 20px;
            }

            .placeholder1 {
                width: 50%;
                height: 50px;
                background: $gray-20;
                border-radius: 5px;
            }

        }
    }

    .place-line-col {
        .placeholder-lines {

            .placeholder-line {
                width: 100%;
                height: 20px;
                background: $gray-20;
                margin: 20px;
                margin-left: unset !important;
            }

            .placeholder-line01 {
                width: 30%;
                height: 20px;
                background: $gray-20;
                margin: 20px;
                margin-left: unset !important;
            }
        }

        .placeholder-line-productdetail {
            width: 200px;
            height: 20px;
            background: $gray-20;
            margin-top: 50px;
        }

        .placeholder-line-productdetail1 {
            margin-top: 30px;

            .placeholder-line-productdetail2 {
                width: 600px;
                height: 300px;
                background: $gray-20;
                margin-top: 30px;

            }
        }
    }
}


.activity {
    position: absolute;
    left: -45%;
    height: 100%;
    width: 45%;
    background-image: linear-gradient(to left, rgba(251, 251, 251, .05), rgba(251, 251, 251, .3), rgba(251, 251, 251, .6), rgba(251, 251, 251, .3), rgba(251, 251, 251, .05));
    background-image: -moz-linear-gradient(to left, rgba(251, 251, 251, .05), rgba(251, 251, 251, .3), rgba(251, 251, 251, .6), rgba(251, 251, 251, .3), rgba(251, 251, 251, .05));
    background-image: -webkit-linear-gradient(to left, rgba(251, 251, 251, .05), rgba(251, 251, 251, .3), rgba(251, 251, 251, .6), rgba(251, 251, 251, .3), rgba(251, 251, 251, .05));
    animation: loading 2s infinite;
    z-index: 45;
}

@keyframes loading {
    0% {
        left: -45%;
    }

    100% {
        left: 100%;
    }
}

.place1 {
    display: flex;
    justify-content: space-evenly;
    margin-top: 30px;

    width: 100%;
    margin-right: 10px;

    .placeholder0 {
        width: 50%;
        height: 50px;
        background: $gray-20;
        border-radius: 5px;
        margin-right: 20px;
    }

    .placeholder1 {
        width: 50%;
        height: 50px;
        background: $gray-20;
        border-radius: 5px;

    }
}

.carousel-img-placeholder{
    @media only screen and (max-width: 576px) {
        height: 135px;
    }
    height: 600px;
}


.skeleton {
	background-color: #e2e5e7;
	background-image:linear-gradient(90deg,  rgba(255, 255, 255, 0),
    rgba(255, 255, 255, 0.5),
    rgba(255, 255, 255, 0));
	background-size: 40px 100%; 
	background-repeat: no-repeat;
	background-position: left -40px top 0;
	animation: shine 1s ease infinite !important;
}