.main_category {
    width: 100%;
    background-color: #fff;
    list-style-type: none;
    margin-bottom: 20px;
    border-radius: 10px;
    overflow: hidden;

    .main_category-header{
        padding: 20px 14px 0;
        span{
            padding-left: 10px;
        }
    }

    .category+.sidebar-subcategory{
        padding: 0px 44px 0;
        background: #f0f4f7;
        border-radius: 7px;
        margin-top: 10px;
        margin-bottom: 10px;
        margin-left: 15px;
        margin-right: 19px;
    }

    .category {
        padding: 15px 14px 0;
        display: flex;
        align-items: baseline;
        cursor: pointer;
        justify-content: space-between;
        .category-leftside {
            display: flex;
           align-items: center;
           width: 100%;
            .category-leftsideimg {
                color: $primary_color;
                img {
                    width: 40px;
                }
            }

            .category-leftsidetitle {
               padding-left: 20px;
               font-size: $s-size;
               margin: unset;
            }
        }

        .category-rightside {
            .category-rightside-icon {
               cursor: pointer;
            }
        }
    }
}