.product-list {
    padding-left: 14px !important;
    padding-right: 0px !important;
    // margin-right: -11px;
    margin-bottom: 15px;
    // margin-bottom: -10px;

    .product-grid {
        font-family: $font-family-2;
        box-shadow: 0 3px 16px 0 rgba(0, 0, 0, .11);

        height: 100% !important;
        cursor: pointer;

        .product-image {
            overflow: hidden;
            position: relative;

            .image {
                text-align: center;
                position: relative;
            }



            img {

                @media only screen and (max-width: 576px) {
                    aspect-ratio: 2/2.8;
                }

                // height: 20rem;
                object-fit: contain;
                aspect-ratio: $app-aspect-ratio;

                width: 100%;

               
            }
            .wishlist-wrong {
                position: absolute;
                top: 5px;
                right: 5px;
                font-size: 25px;
            }

        }

        .product-content {
            background: $white;
            // padding: 5px 0px 10px 10px;
            padding: 5px 0px 10px 0px;
            margin-right: 15px;
            margin-left: 10px;

            .title {
                color: $paragraph-color;
                font-size: 14px;
                min-height: 50px;
                font-family: $font-family-2;

                font-weight: 400;
                display: -webkit-box;
                -webkit-line-clamp: 3;
                /* Number of lines to show */
                -webkit-box-orient: vertical;
                overflow: hidden;
                text-overflow: ellipsis;
                padding: unset !important;
            }

            .product-ratingsContainer {
                padding: 5px;
                display: inline-block;
                color: #fff;
                padding: 4px 2px 3px 6px;
                border-radius: 3px;
                margin-top: 5px;
                font-weight: 500;
                font-size: 12px;
                vertical-align: middle;
                background-color: $primary_color;
            }

            .rating-count {
                padding-left: 8px;
                margin-top: 5px;
                font-weight: 500;
                font-size: $xs-size;
                color: #878787;
            }

            .product-brand {
                font-size: 12px;
                font-weight: 500;
                color: $black;
                margin-bottom: 5px;
                overflow: hidden;
                text-overflow: ellipsis;
                white-space: nowrap;
            }


        }

    }
}



.carousel-indicators [data-bs-target] {
    width: 6px !important;
    height: 6px !important;
    border: 1px solid #fff !important;
    opacity: 1 !important;
    background: transparent !important;
    border-radius: 29px !important;
}



@media only screen and (max-width: 576px) {
    .product-image img {
        // max-height: fit-content !important;
        // height: 140px !important;
    }

    .offer-label-revamp {
        // font-size: $xs-size !important;
    }
}

.product-card-cart_btn {
    .product-card-btn-2 {
        background: none !important;
        border: 1px solid $primary_color !important;
        color: $primary_color !important;
        margin-right: unset !important;
        font-size: 12px !important;
        padding: 5px !important;

        &:hover {
            background: $primary_color !important;
            border: none;
            color: $white !important;
        }
    }
    .product-card-btn-outofstock {
        border: 1px solid $primary_color !important;
        margin-right: unset !important;
        font-size: 12px !important;
        padding: 5px !important;
    }

    

    .product-card-btn-2loader {
        background: none !important;
        border: 1px solid $primary_color !important;
        color: $primary_color !important;
        margin-right: unset !important;
        font-size: 12px !important;
        padding: 5px !important;

        @media only screen and (max-width:991px) {
            margin-left: unset !important;
            margin-top: unset !important;
        }
    }

    .product-card-btn-icon {
        margin-top: -3px;
        font-size: 18px;
    }
}

.product-card-variant-dropdown {
    width: 100%;

    .product-card-selected-variant {
        display: flex;
        justify-content: space-between;
        border: 1px solid $border_color;
        border-radius: 5px;
        padding: 2px 5px 2px 5px;
        font-size: 12px;

        .selected-variant-text {
            font-size: 15px;
        }

        .selected-variant-icon {
            font-size: 15px;
        }

        .dropdown-toggle::after {
            display: none;
        }

        .dropdown-toggle {
            width: 100%;
        }

        .varinat-caret-icon {
            font-size: 16px;
            float: right;
        }
    }



    .dropdown-menu.show {
        width: 100%;
        inset: 5px auto auto -5px !important;
        box-shadow: 0 3px 16px 0 rgba(0, 0, 0, 0.11);
    }

}

.cart-btn-loader {
    border-color: $primary_color $primary_color transparent !important;
}

.cart-increment-container {
    padding-left: unset !important;
    padding-right: unset !important;
    padding-right: 15px !important;
    padding-left: 15px !important;
    border: 1px solid $primary_color;
    border-radius: 5px;

    .addon-qunatity {
        font-size: 14px !important;
    }
}

.card-variant-title {
    padding: 2px 5px 2px 0px;
    font-size: 14px;
}

.product-card-button-div {
    @media only screen and (max-width: 991px) {
        box-shadow: none !important;
        position: initial !important;

        .product-card-btn-2 {
            margin: unset !important;
        }
    }
}


.custom-mobilevariant-modalsheet {
    height: auto !important;
    border-top-left-radius: 25px !important;
    border-top-right-radius: 25px !important;

    .custom-filter-sheet-haeder {
        padding: 15px 15px 10px 15px;
        display: flex;
        border-bottom: 1px solid #eee;
        justify-content: space-between;
        font-family: $font-family-2;
        font-size: 16px;
        font-weight: 500;
        margin-right: 10px;
    }

    .filter-body {
        display: flex;
        height: calc(90vh - 122px);
        background-color: rgb(255, 255, 255);
        border-radius: 0px;
    }
}

.product-card-variant-dropdownlist {
    // box-shadow: 0 3px 16px 0 rgba(0, 0, 0, 0.11);
    background: white;

    .product-card-variant-listitem {
        border: 1px solid $border_color;
        padding: 5px;
        border-radius: 2px;
        margin: 8px;
        width: unset !important;

        @media only screen and (max-width: 991px) {
            margin: 15px;
        }

        .variant_title {
            font-size: 14px;
        }

        &:hover {
            box-shadow: rgba(48, 48, 48, 0.04) 0px 0px 1px, rgba(96, 96, 96, 0.16) 0px 2px 4px;
        }
    }

    .selected_variant_active {
        border: 2px solid $primary_color;
    }
}

.product-price {
    align-items: center;
    display: flex;

    .Paragraph-16-M--Semibold {
        font-size: $s-size;
        display: inline;
        color: $black;
        font-weight: 700;
        font-family: $font-family-2;
    }

    .offer-revamp {
        font-family: $font-family-2;
        color: $paragraph-color;
        display: inline-block !important;
        margin-left: 7px;
        text-decoration: line-through;
        font-size: $s-size;
    }

    .offer-label-revamp {
        font-family: $font-family-2;
        font-size: $s-size;
        // padding: 0px 3px;
        margin-left: 8px;
        color: $primary_color;
        font-weight: 500;
    }
}

.productlist-loader{
    color:$primary_color;
    margin: 10px 0px;
}