.field-holder {
  position: relative;
  margin-bottom: 1.5em;
}

.custom-input {
  border: 0;
  border-bottom: 1px solid rgb(206, 206, 222);
  outline: none;
  transition: .2s ease-in-out;
  font-size: $s-size !important;
}


.custom-label {
  position: absolute;
  top: 0;
  left: 0;
  display: flex !important;
  align-items: center;
  color: grey;
  transition: .2s ease-in-out;
  font-size: $s-size0 !important;

}

.custom-input,
.custom-label {

  width: 100%;
  height: 35px;
}

.custom-textarea {
  height: 70px;
  margin-top: 9px;
}

.custom-input:focus+.custom-label,
.custom-input:valid+.custom-label {
  color: $primary_color;
  font-size: $s-size;
  top: -22px;
  pointer-events: none;
}

.custom-input:focus {
  border-bottom: 3px solid $primary_color;
}

.custom-input.is-error {
  border-bottom: 1px solid red;
}

.error-icon {
  position: absolute;
  bottom: 10px;
  right: 10px;
  color: red;
}

.checkout-address-form-title {
  animation: shake 300ms
}

.custom-input.is-error+.custom-label {
  animation: shake 300ms
}

.custom-error-text{
  color: red;
  animation: shake 300ms
}

@keyframes shake {
  25% {
    transform: translateX(4px)
  }

  50% {
    transform: translateX(-4px)
  }

  75% {
    transform: translateX(4px)
  }
}

.error-text {
  position: relative;
  bottom: 20px;
  color: red;
}


.customer-profile {
  @media only screen and (min-width: 786px) {
    border: 1px solid #eee;
    border-radius: 10px;

  }

  padding: 2rem;
}

.custom-input-disabled {
  pointer-events: none;
  border-bottom: 1px dashed rgb(206, 206, 222);
}

.custom-change-icon {
  position: absolute;
  bottom: 4px;
  right: 4px;
  color: $primary_color;
  cursor: pointer;
}

.formH {
  display: flex;
  flex-direction: column;
  text-align: center;
  position: relative;


  .profileImg {
    border-radius: 50%;
    overflow: hidden;
    width: 100px;
    height: 100px;
    border: 1px solid #ddd;

  }

}

.profileUpdate {
  position: absolute;
  font-size: 30px;
  bottom: -8px;
  display: flex;
  left: 52%;


  color: #ff9933;

}

.custom-otp-modalsheet{
  height: auto !important;
}

.form-check-input:checked {
  background-color: $primary_color !important;
  border-color:$primary_color !important;
}

.form-check-input:focus{
  border-color: $primary_color !important;
  box-shadow: none !important;
}

input[name="address"].form-check-input:checked {
  background-color: blue !important;
  border-color: blue !important;
}

.form-control:focus {
  border-color:$border_color !important;
  outline: 0;
  box-shadow: none !important;
}