.TrackerOrder1 {
  padding-left: 30px;
  align-items: center;
  border: 1px solid #dbdbdb;
  margin: 10px 0px;

  @keyframes shipped {
    0% {
      background-color: $primary_color;
      height: 0px;
    }

    100% {
      background-color: $primary_color;
      height: 105px;
    }
  }

  @keyframes outdelivery {
    0% {
      background-color: $primary_color;
      height: 0px;
    }

    100% {
      background-color: $primary_color;
      height: 210px;
    }
  }

  @keyframes delivered {
    0% {
      background-color: $primary_color;
      height: 0px;
    }

    100% {
      background-color: $primary_color;
      height: 315px;
    }
  }

  @keyframes animation-cirlce {
    0% {
      background: $primary_color;
    }

    100% {
      background: $primary_color;
    }
  }

  .steps {
    height: 313px;
    margin-bottom: 50px;
    margin-top: 30px;
    position: relative;

    .steps-circle-parent {
      position: relative;

      .circle {
        display: flex;
        height: 15px;
        width: 15px;
        margin-bottom: 90px;
        border-radius: 50%;
        background: #e0e0e0;
        transition: all 1000ms ease;
        transition-delay: 0s;
      }

      .circle.active {
        background: $primary_color;
      }

      .order-tracking-title {
        position: absolute;
        top: -3px;
        left: 30px;
        font-size: $s-size;
        font-weight: 500;
      }

      .active+.order-tracking-title {
        color: $primary_color;
      }

      .order-tracking-date {
        color: #878787;
        font-size: $xs-size;
      }
    }
  }

  .sprogress-bar {
    position: absolute;
    width: 3px;
    left: 6px;
    top: 15px;
    height: 100%;
    z-index: -1;
    background: #e0e0e0;

    .indicator {
      position: absolute;
      width: 100%;
      animation-duration: 1s;
      animation-fill-mode: forwards;
      transition: all 1000ms ease;
    }
  }






}

















.TrackerOrder {
  width: 100%;
  border: 1px solid #dbdbdb;
  margin-top: 10px;
  padding: 40px 40px 50px 40px;

  .desktop-tracker-steps {
    display: flex;
    width: 100%;
    align-items: center;
    justify-content: space-between;
    position: relative;

    .steps-circle-parent {
      position: relative;

      .desktop-tracker-circle {
        display: flex;
        align-items: center;
        justify-content: center;
        height: 15px;
        width: 15px;
        border-radius: 50%;
        background: #e0e0e0;
        transition: all 200ms ease;
        transition-delay: 0s;
      }

      .desktop-tracker-circle.active {
        background: $primary_color;
      }

      .desktop-order-tracking-title {
        position: absolute;
        top: -31px;
        left: -50px;
        text-align: center;
        width: 128px;
        font-size: $s-size;
        font-weight: 500;
      }

      .desktop-order-tracking-date {
        position: absolute;
        top: 24px;
        left: -50px;
        text-align: center;
        width: 128px;
        color: #878787;
        font-size: $xs-size;
        font-weight: 500;
      }

    }




    .desktop-tracker-progress-bar {
      position: absolute;
      height: 4px;
      width: 100%;
      background: #e0e0e0;
      z-index: -1;

      .desktop-tracker-indicator {
        position: absolute;
        height: 100%;
        width: 0%;
        animation-duration: 1s;
        animation-fill-mode: forwards;
        transition: all 1000ms ease;
      }

    }








  }


  @keyframes shipped-desktop {
    0% {
      background-color: $primary_color;
      width: 0px;
    }

    100% {
      background-color: $primary_color;
      width: 33.3333%;
    }
  }

  @keyframes outdelivery-desktop {
    0% {
      background: $primary_color;
      width: 0%;
    }

    100% {
      background: $primary_color;
      width: 66.6667%;
    }
  }

  @keyframes delivered-dektop {
    0% {
      background-color: $primary_color;
    }

    100% {
      background-color: $primary_color;
      width: 100%;
    }
  }

  .active+.desktop-order-tracking-title {
    color: $primary_color;
  }
}