.ourproducts {
    margin-left: 20px;
    padding-top: 20px;
    justify-content: space-between;
    margin-right: 22px;
    margin-bottom: 10px;
    display: flex;
    align-items: center;



    .ourproducts-title {
        font-size: 32px;
        font-weight: 600;
        // border: 1px solid green;
        display: flex;
        justify-content: start;
    }

    .ourproduct-title-types {
        display: flex;
        justify-content: end;
        flex-direction: row-reverse;
        align-items: center;
        text-align: center;
        color: $primary_color;
        font-size: 14px;
        // border: 1px solid black;

        .ourproduct-title-types-titles {
            font-size: 16px;
            font-weight: 600;
            margin: 0px 0px 0px 20px;
            // padding-top: 20px;
            cursor: pointer;
            display: flex;
            justify-content: center;
            flex-direction: row-reverse;
            align-items: center;
            text-align: center;


        }
    }







}

@media only screen and (max-width: 767px) {
    .ourproducts {


        .ourproducts-title {
            font-size: 22px;
            font-weight: 600;
            display: flex !important;
            justify-content: center !important;
            text-align: center;
        }

        .ourproduct-title-types {
            display: flex;
            justify-content: center;
            flex-direction: column;

            align-items: center;
            text-align: center;

            .ourproduct-title-types-titles {
                font-size: 14px;
                font-weight: 600;
                padding: 5px 0px;
                margin: unset;

            }
        }
    }
}



.qqqqqq{
    margin: 10px;
}

.ourproducts-header-main {
    position: relative;

    @media only screen and (max-width: 991px) {
        padding-left: 20px !important;
    }

    @media only screen and (min-width: 991px) {
        // padding-left: 73px !important;
        // padding-right: 53px !important;
    }

    // border: 1px solid black;
    .ssss{
        // margin-top: 20px;
        margin: 5px 5px 5px 0px;
        .ourproducts-main-cards{
            // margin-top: 20px;
            height: 100%;
            cursor: pointer;
            box-shadow: 0 3px 16px 0 #0000001c;
            font-family: $font-family-2;
            .ourproducts-swiper {
                // margin-top: 20px;
              
           
                .ourproducts-swiper-image-main-div {
                    width: 100%;
        
                    .ourproducts-swiper-image {
                        display: block;
                        width: 100%;
                        // object-fit: contain;
                        aspect-ratio: $app-aspect-ratio;
                        //  max-height: 400px;
                    }
        
                }
        .ourproducts-title-main{
            padding: 0px 5px;
        }
                .ourproducts-swiper-image-title {
                    font-size: 14px;
                    margin-top: 10px;
                  
                    height: 50px;
                    font-weight: 400;
                    color: $paragraph-color;
                    font-family: $font-family-2;
                    display: -webkit-box;
                    -webkit-line-clamp: 3; /* Number of lines to show */
                    -webkit-box-orient: vertical;
                    overflow: hidden;
                    text-overflow: ellipsis;
                }
        
                .ourproducts-swiper-price {
                    font-size: 14px;
                 
                    display: inline;
                    color: #000;
                    font-weight: 700;
                    font-family: $font-family-2;
                }
            }
        }
       
    }
    

    .ourproducts-header-prev {
        position: absolute;
        top: 50%;
        background: $primary_color;
        border: 1px solid $primary_color;
        color: $white;
        padding: 1px;
        border-radius: 5px;
        // background: $primary_color;

        // color: gray;

        z-index: 1;
        cursor: pointer;

        @media only screen and (min-width: 991px) {
            left: 72px;
        }

    }

    .ourproducts-header-next {
        position: absolute;
        top: 50%;

        border: 1px solid $primary_color;
        padding: 1px;
        // background: $primary_color;
        color: $white;
        background: $primary_color;
        z-index: 1;
        border-radius: 5px;
        cursor: pointer;

        @media only screen and (min-width: 991px) {
            right: 53px;
        }
    }

}


.ourproduct-details-dropdown-maintitle {
    .dropdown-menu.show {
        inset: 6px -17px auto auto !important
    }
}

.allproducts-main-row {

    --bs-gutter-x: none !important;
    --bs-gutter-y: none !important;
    display: flex;
    flex-wrap: wrap;
    margin-top: none !important;
    margin-right: none !important;
    margin-left: none !important;
}


// ..........................frequently bougth images..................



.ProductBought-main-head {
    margin-top: 10px;
    margin-left: 50px;
}

.productbought-main-title-content {
    display: flex;
    flex-wrap: wrap;
    //   align-items: center;
}

.productsbought {
    display: flex;
    flex-wrap: wrap;

}

.productsbought-sub-main {
    width: 150px;
}


.products-bought-sub-div {
    // display: flex;
    // flex-direction: row;



}

.productbought-djhf {
    padding: unset !important;
}

.productbought-image-main-div {
    aspect-ratio: 4/4 !important;
    // border: 1px solid black;
    // width: 200px;
}


.productbought-images-image-imagess {
    position: relative;
    object-fit: cover;
    width: 100%;
    // aspect-ratio: 4/4;
}

.productbought-image-checkout {
    position: absolute;
    top: 5px;
    right: 5px;
    width: 15px;
    height: 15px;
    // width: 30px !important;
    //  z-index: 10;
}







.product-bought-icon {
    font-size: 15px;
    margin: 0px 10px 30px 10px;
    display: flex;
    justify-content: center;
    // border: 1px solid green;
}

.productsbought-icn-and-details {
    display: flex;
    // justify-content: start;
    // align-items: center;
}

.product-plus-icon-div {
    display: flex;
    justify-content: center;
    align-items: center;
}







@media only screen and (max-width: 767px) {
    .productbought-images-image {
        object-fit: contain;

        height: 100px;
    }

    .productsbought {

        .products-bought-sub-div {
            width: 110px !important;


        }
    }
}

.product-bought-output {
    display: flex;
    flex-direction: column;
    justify-content: start;
    align-items: center;
    margin-left: 30px;
    margin-top: 20px;
    font-family: $font-family-2;
}

.product-bought-button {
    width: fit-content !important;
    padding: 10px 20px !important;

}

.product-boudht-price-div {
    font-family: $font-family-2;
    font-size: 18px;
    margin-bottom: 10px;
  
    font-weight: 500;
    color: $black;
}

.product-boudht-price-title-div {
    font-family: $font-family-2;
    font-size: 15px;
    margin-bottom: 10px;

    font-weight: 400;
    color: $paragraph-color;
}

.product-boudht-price- {
    margin-bottom: 10px;
}









// .................ProductsboughtMobile...................

.ProductBought-main-head-mobile {
    margin-top: 10px;
}

.ProductsboughtMobile {
    border: 1px solid $border_color;
    font-family: $font-family-2;
}

.ProductsboughtMobile-images {
    display: flex;
    flex-direction: row !important;
    // flex-wrap: wrap;
    justify-content: center;
    border-bottom: 1px solid $border_color;
    padding: 10px;
    overflow-x: scroll;

}

.ProductsboughtMobile-images-sub-div {
    display: flex;
    align-items: center;
    //    margin-left: 10px;
}

.productbought-mobile-main-main-div {
    border-bottom: 1px solid $border_color ;
    display: flex;
    justify-content: start;
    align-items: center;

}

.product-mobile-bought-icon {
    margin: 5px;
    font-size: 14px !important;
}

.productbought-mobile-checkbox-main-div {
    padding: 10px;

    // border-right: 1px solid gray;
}

.productbought-mobile-checkbox {
    // width: 20px;
    // height: 20px;

}

.productbought-mobile-title-images-title {
    font-size: 12px;
    margin-top: 5px !important;

    font-weight: 400;
    color: $paragraph-color;
    margin: unset;


}

.productbought-mobile-main-title-price-main {
    // padding:  0px  10px;
    // border-left: 1px solid gray;
}

.productbought-mobile-title-images-price {
    font-size: 13px;

    display: inline;
    color: #000;

    font-weight: 700;
}

@media only screen and (max-width: 767px) {
    .productbought-mobile-main-title-price-main {

        // border-left: 1px solid gray;
    }

    .productbought-mobile-checkbox-main-div {


        border-right: none;
    }
}

.productbought-mobile-total-price {
    width: 100%;
}

.productbought-mobile-output {
    margin: unset !important;
}

.productmobile-bought-image-imagesall {
    max-width: 100px !important;
    max-height: 100px !important;
    object-fit: contain;
}

.productbought-mobile-inital {
    border-radius: 0 0 0.8rem 0.8rem;

    align-items: center;
    text-align: center;
    margin: auto 2px;
    padding: 13px 17px 12px;
    text-align: center;
    color: $paragraph-color;
    font-family: $font-family-2;
}

.productbought-mobile-downarrow {
    float: right;
    color: $paragraph-color;

}

.product-boudht-mobile-price-div {
    font-family: $font-family-2;
    font-size: 14px;

    margin-top: 15px;
    font-weight: 500;
    color: $black;
}

.product-boudht-mobile-price-title-div {
    font-family: $font-family-2;
    font-size: 13px;

    margin-top: 15px;
    font-weight: 400;
    color: $paragraph-color;
}

.product-bought-mobile-button {
    margin-top: unset !important;
}



// ..........................wishlist....................









.DNBFSF{
    border: 1px solid black;
    margin-top: 10px !important;
}

.wishlist-button{
    // margin-right: 10px;
}




.wishlist-main-div{
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
   
}
.whishlist-image{
    position: relative;
    object-fit: cover !important;
}
.whishlist-image-icons{
    position: absolute;
    top: 5px;
    right: 5px;
    z-index: 1;
    font-size: 30px;
    color:$white;


}
// .whishlist-image-icons:hover{
//   color:gray ;
// }
.wishlist-col{
   padding:  10px  !important;

}
// @media  only screen and (max-width:991px){
//     .wishlist-col{
//         padding: 10px !important;
//      }
// }
.wishlist-maintitle{
    font-size: 18px;
    font-weight: 600;

    font-family: $font-family-2;
    margin-left: 10px;

}
.mywishlist-item-count{
    color: #999;
    
    font-weight: 400;
    
}