body {
  font-family: $font-family-primary !important;

}

.outer_div {
  display: flex;
  // position: relative;


  .scroll-content-left {
    .zoom-detail-image {
      position: sticky;
      top: 114px;
    }
  }

  .scroll-content-right {
    height: 100%;
    right: 0;


    .product_Short_description {
      color: $paragraph-color;
      font-size: 15px;
      font-family: $font-family-2;
    }


    .productBank {
      margin: 12px 0px 0px 0px;

      .offerCard {
        font-weight: 400;
        font-size: $s-size;
        margin: 8px 0px;
        color: $black;

        .learnMore {
          background-color: transparent;
          border: none;
          color: #828282;
          cursor: pointer;
          font-size: $xs-size;
          font-weight: 400;
          text-decoration-line: underline;
        }
      }

      .viewMore {
        border: none;
        font-size: $xs-size;
        cursor: pointer;
        font-weight: 700;
      }
    }

    .tab-container::-webkit-scrollbar {

      width: 2.5px;
    }

    .tab-container::-webkit-scrollbar-thumb {
      border-radius: 10px;
      -webkit-box-shadow: inset 0 0 15px #FD7900;
    }

    .productBankOffer {
      font-weight: 700;
      font-size: $s-size;
      color: $black;
      margin-bottom: 8px;
    }

    .productBankOffers {
      font-weight: 700;
      font-size: $s-size;
      color: $black;
    }

    .product-price {
      align-items: center;
      display: flex;

      .Paragraph-16-M--Semibold {
        font-size: 20px;
        display: inline;
        font-weight: 500;
        color: $primary_color;
        font-family: $font-family-2;
      }

      .offer-revamp {
        color: $paragraph-color;
        display: inline-block !important;
        margin-left: 5px;
        font-size: 13px;
        text-decoration: line-through;
        font-family: $font-family-2;
      }

      .offer-label-revamp {
        font-size: 16px;
        padding: 0px 5px;
        margin-left: 5px;
        // margin-top: 5px;
        color: $primary_color;
        // font-weight: 600;
      }
    }
  }
}

.ratings {
  display: flex;
  margin-top: 3px;

  .product-ratingsContainer {
    display: inline-block;
    color: $white;
    padding: 5px;
    border-radius: 3px;
    // margin-top: 5px;
    font-weight: 400;
    font-size: $xs-size;
    vertical-align: middle;
    background-color: $primary_color;
  }

  .rating-count {
    padding-left: 8px;
    margin-top: 5px;
    font-weight: 500;
    font-size: $s-size;
    color: #878787;
  }
}







.mySwiper {
  margin-top: 20%;
  margin-bottom: 20%;

  .desktop-swiperslide {
    border: 1px solid grey;
    margin-bottom: 5px;
    cursor: pointer;
    height: 129px !important;

    img {
      width: 100%;
      object-fit: contain;
      height: 125px;
    }
  }
}

.mySwiperMobile {
  .swiper-slide {
    max-height: 340px;
    height: 360px;

    img {
      width: 100%;
      object-fit: contain;
      height: 100%;
    }
  }


  .swiper-pagination {
    position: relative;
    margin-top: 20px;

    .swiper-pagination-bullet-active {
      width: 25px;
      border-radius: 20px;
      background-color: $primary_color;
    }
  }
}

.desktop-swiperslide.swiper-slide-active {
  border: 2px solid $primary_color !important;
}

.previous,
.next {
  text-align: center;
}

._3AsE0T {
  max-height: 400px;
  overflow: hidden;
}

._3dtsli {
  // border: 1px solid #f0f0f0;

  ._5pFuey {
    display: -webkit-flex;
    display: -ms-flexbox;
    display: flex;
    -webkit-align-items: center;
    -ms-flex-align: center;
    align-items: center;
    -webkit-justify-content: space-between;
    -ms-flex-pack: justify;
    justify-content: space-between;
    padding: 0px 0px 5px 0px;
    font-weight: 500;
    font-size: 18px;
    font-family: $font-family-2;
    color: #212121;
  }

  ._1UhVsV {
    ._3k-BhJ {
      border-top: 1px solid #f0f0f0;
      padding: 16px 14px 7px;
      ;
      font-size: 14px;

      .flxcaE {
        padding-bottom: 16px;
        font-size: $s-size0;
        white-space: nowrap;
        font-weight: 500;
      }

      ._14cfVK {
        td {
          ul {
            list-style-type: none;
          }
        }
      }
    }
  }



}

.readmore {
  padding: 10px;
  width: 100%;
  text-align: left;
  margin-top: -1px;
  box-shadow: none;
  font-size: 16px;
  color: $primary_color;
  border: 1px solid #f0f0f0;
  font-weight: 400;
  cursor: pointer;
}

.iop {
  // margin-left: 15px;
  width: 78% !important;

  img {
    // height: 450px;
  }

}

.buttons-product-detaildiv {
  margin: unset;
  padding: unset;
}

.product-detail-buttongroup {
  display: flex;

  // margin-left: 5%;
  .product-detail-button {
    background: $primary_color;
    border: none;
    border-radius: 5px;
    color: #fff;
    font-size: 15px;
    // text-transform:capitalize !important;
    // font-weight: 600;
    text-align: center;
    cursor: pointer;
    // margin-right: 15px;
    // margin-left: 15px;
    margin-top: 2px;
    padding: 10px;
    width: 100%;

    @media screen and (max-width: 991px) {
      width: 100% !important;
      margin-left: 5px;
      // text-transform:capitalize !important;
    }
  }

  .product-detail-buttonactive {
    color: $black;
    border: 1px solid black;
    background: none;
  }

  .btn-2 {
    background: none;
    // border: 1px solid $border_color;
    color: $secondary_color;

    &:hover {
      // border: 1px solid $hover_border_color;
    }
  }
}

.product-detail-modal {
  position: fixed !important;
  top: 50%;
  left: 50%;
  width: 30em;
  margin-top: -14em;
  margin-left: -15em;
}

@media screen and (max-width: 991px) {
  .buttons-product-detaildiv {
    bottom: 0%;
    margin: unset !important;
    padding: unset !important;
    // position: fixed;
    // right: calc((100vw - 100%)/2);
    background: $white;
    // box-shadow: rgba(0, 0, 0, 0.15) 0px -1px 3px 0px;
    // z-index: 10;
  }

  .product-detail-buttongroup {
    margin-left: 0%;
    margin: 0px 10px;
  }

  .product-detail-buttongroup .product-detail-button {
    margin-top: 4px;
    // margin-left: 10px;
    margin-right: 5px;
    padding: 9px;
    font-size: $s-size;
    margin-bottom: 5px;
  }
}

.my-custom-toast.Toastify__toast-container--bottom-center {
  bottom: 6em;

}

.toast-view-btn {
  font-size: $xs-size;
  background: $primary_color;
  border: none;
  border-radius: 24px;
  color: #fff;
  font-weight: 600;
  text-align: center;
  cursor: pointer;
  padding: 4px;
  width: 30%;
}

@media only screen and (max-width: 480px) {
  .Toastify__toast-container {
    left: 35px;
    right: 35px;
    width: 80%;
  }
}

.ss>div>img {
  max-height: 450px;
  object-fit: contain;
}

.product_detail_attributes {
  font-size: 15px;
  font-family: $font-family-2;
}

.product_detail_attributes_parent {
  display: flex;
  flex-direction: column;
  padding: 0px 10px 3px 0px;

}

.attributes-item {
  display: block;
  padding: 5px 0px 0px 0px;
  color: $paragraph-color;

  .attributes-item-right {
    color: $paragraph-color;
  }
}

.custom-accordion-header {
  .accordion-button {
    font-size: 14px;
    padding: 10px;
    background: $primary_color;
    position: initial;
    color: $white;
    font-weight: 500;
  }
}

.accordion-button:not(.collapsed) {
  background-color: $primary_color !important;
  color: $white !important;
}

.accordion-button:focus {
  box-shadow: none !important;
  border-color: rgba(0, 0, 0, .125) !important;
}



.product_description {
  padding: 5px 0px 0px 0px;
  font-family: $font-family-2;
  font-size: 15px;
}

.product-titlebold-heading {
  font-size: 18px;
  font-weight: 500;
  font-family: $font-family-2;
}

.carousel-addon-comp {
  margin-left: unset !important;
  margin-top: unset !important;
}

.carousel-addon-customnext {
  z-index: 2;
  color: $white;
}

.carousel-addon-customprevious {
  z-index: 2;
  color: $white;
  margin-left: 5px;
}

.add-ao-icon-revamp {
  margin-top: 4px;
  width: 100%;
  border-radius: 2px;
  display: flex;
  justify-content: center;
  align-items: center;
  cursor: pointer;
  border-radius: 5px;
  border: solid 1px $primary_color;
  font-size: 12px;
  padding: 7px;
}

.delivery_slot_list {

  display: flex;
  text-align: center;
  border-right: 1px solid #bdbdbd;

  .delivery_slot {
    padding: 10px;
    width: 34%;
    border-top: 1px solid #bdbdbd;
    border-left: 1px solid #bdbdbd;
    border-bottom: 1px solid #bdbdbd;
    // border-right: 1px solid #bdbdbd;
    cursor: pointer;

    @media screen and (max-width: 991px) {
      font-size: 12px;
      padding: 6px;
    }
  }

  .activedelivery_slot {
    font-weight: 500;
    color: $primary_color;
    border-right: unset;
    border: 1px solid $primary_color ;
  }
}

.delivery_slot_time_list {
  margin-left: unset !important;
  text-align: center;

  .delivery_time_slot {
    padding: 10px;
    width: 32%;
    border: 1px solid #bdbdbd;
    margin-right: 5px;
    margin-top: 5px;
    cursor: pointer;

  }

  .activedelivery_slot {
    font-weight: 500;
    color: $primary_color;
    border-right: unset;
    border: 1px solid $primary_color ;
  }
}

.time_dropdown {
  border: 1px solid #bdbdbd;
  padding: 10px;
  cursor: pointer;
  width: fit-content;
}

.dropdown_icon {
  margin-top: -3px;
  margin-left: 5px;
}

.time-dropdown-menu {
  margin-left: 16px !important;
}

.time_dropdown {
  font-size: 12px !important;

}


.slot_custom_heading {
  color: $primary_color;
  font-weight: 500;
  margin-top: 10px;
}

.custom-time-slot {}

.addon-quantity {
  display: flex;
  width: 100%;
  justify-content: space-between;
  padding-left: 10px;
  padding-right: 10px;

  .addon-increment {
    cursor: pointer;
  }

  .addon-qunatity {
    font-size: 14px;
    font-weight: 500;
  }

  .addon-decrement {
    cursor: pointer;
  }
}

.active-bg-orange {
  background: $primary_color;
  color: $white;
}

.delivery_slot_parent {
  padding: 10px 20px;
}

.bundel_deals_parent {
  padding: 10px;

  .bundel_deals_products {
    border-top: 1px solid #eee;
    border-bottom: 1px solid #eee;
    border-left: 1px solid #eee;
    border-right: 1px solid #eee;

    .bundel_deals_prod_padding {
      padding-top: 20px;
      padding-bottom: 20px;
    }
  }

  .bundel_deals_bottom {
    border: 1px solid #eee;
    border-bottom: 1px solid #eee;

    .bundel_deals_bottom_content {
      padding: 10px;
    }
  }
}

.product-customcheckbox {
  top: 12px;
  position: absolute;
  right: 13px;
  width: 20px;
  height: 20px;
}

.custom_heading {
  padding-bottom: 10px;
  padding-top: 10px;
  font-weight: 500;
}

// .bundel_deals_bottom_content{
//   display: flex;
//   .bottom_title{
//      font-weight: 500;
//   }
//   .bottom_right_quantity{
//     color: #828282;
//   }
// }
// .bundel_deals_right{
//   float: right;
// }


.myprodcutdetailSwiper {
  margin-top: 1%;

  .desktop-myproductdetailswiperslide {
    // border: .1px solid grey;
    cursor: pointer;
    margin-bottom: 5px;

    // padding: 3px;
    img {
      // width: 100%;
      // height: 100%;
      width: 100%;
      aspect-ratio: 3/4;
      object-fit: contain;
      opacity: .5;
      // height: 60px;
      // object-fit: contain;

    }
  }

  .swiper-slide-thumb-active {

    // border: 1px solid $primary_color;
    img {
      opacity: 1;
    }

    // &:hover {
    //   opacity: 1;
    // }
  }
}

.mybigSwiper {
  img {
    max-height: 460px;
    width: 100%;
  }
}

.addon_product_title_heading {
  font-weight: bold;
}

.addon_product_title_quantity {
  color: #535665;
}

.addon_selected_heading_con {
  display: flex;
  flex-wrap: wrap;
  margin-bottom: 10px;
}

.product-detail-btn-icon {
  margin-top: -5px;
  margin-right: 10px;
  font-size: 20px;
  @media screen and (max-width:991px) {
    margin-right: 10px;
  }
}

.custom-cartbtn-width {
  width: 60% !important;
}

.cart-btn:hover {
  opacity: 0.9;
}

.custom-stock {
  color: $black;
  font-weight: 500;
}

.custom-variant-tooltip {
  .border-right {
    border-left: 1px solid $border_color;
    margin-left: 5px;
    padding-left: 5px;
    font-family: $font-family-2;
  }
}

input[type="checkbox"]:checked {
  border-color: red;
  background-color: red;
}

.product-detal-cart_btn .product-detail-button {
  width: 80%;
}

.rating_slider {

  img {
    width: 100%;
  }
}

.ratings{
  margin-bottom: 15px;
}

.ratings_user_headr {
  display: flex;
  margin-bottom: 3px;
  font-size: 13px;

  .ratings_user_title {
    margin-left: 5px;
    font-weight: 500;
  }
}

.unselected_value {
  color: lightgray;
}

.selected_value {
  color: $primary_color;
}

.ratings_container {
  margin-bottom: 15px;
}

.ratings_user_description {
  margin-top: 3px;
  font-size: 12px;
}

.rating_summary {
  margin-left: 15px;
  top: 3px;
  position: relative;
}

.ratings-header {
  color: #85481d;
  margin-bottom: 15px;
  font-size: 20px;
  font-weight: 500;
  padding-top: 0px;
  width: 100%;
}
.dimensions-productsdetails{
  padding: unset;
}
.qqqqq{
  padding: none !important;
  
}

.reviewtitle-heading{
  margin-left: none !important;
  margin-left: 0px !important;
}
.review-and-rating{
  margin: 0px 0px !important;
}

@media screen and (min-width:991px){
  .review-and-rating{
    margin: 0px 20px !important;
  }
}


.heart-productdeatail{
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
}
.eightcardbanner{
  width: 100%;
  object-fit: contain;
  margin: 10px 0px;

}
.eightbanner-images{
  margin: 0px 3px 0px 1px !important;
}
.cart-quentity-div{
  margin-right: 0px !important;
  padding-right: 0px !important;
}