.header {
  width: 100%;
  background: $header-bg;
  display: flex;
  align-items: center;
  box-shadow: none;
  justify-content: space-between;
  position: sticky;
  top: 0;
  z-index: 10;
  color:$header-text-color;
  .logo {
    margin-top: 1px;
    margin-left: 15px;
  }

  .logo img {
    object-fit: contain;
    height: 50px;
    margin: 15px 0px 10px 0px;
    width: 100px;
  }

  .logo a {
    display: block;
    height: 100%;
  }

  .searchbar {
    width: 650px;
    margin-left: 25px;
  }



  .header-item-right {
    display: flex;
    margin-right: 15px;
    position: relative;

    .Profile-Dropdown {
      display: flex;
      padding: 3px;

      .Profile-Dropdown-icon {
        padding-right: 10px;
        color: $primary_color;
        display: flex;
        align-items: center;
        text-align: center;
      }

      .Profile-Dropdown-name {
        font-size: 14px;
      }
    }

    .Profile-Dropdown-icon-arrow {
      position: absolute;
      top: -23px;
      font-size: 23px;
      left: 65%;
    }
  }

  .header-item-left {
    display: flex;
  }

  .header-address {
    display: flex;
    margin-left: 20px;
    margin-top: 17px;
    cursor: pointer;
    .location-marker {
      margin-top: 13px;
      margin-right: 4px;
    }

    .header-address-line1 {
      font-size: $xs-size;
    }

    .header-address-line2 {
      font-size: $s-size;
      font-weight: 400;
    }
  }

  .dropdown-up-title {
    font-size: $xs-size;
  }

  #nav-dropdown-dark-example {
    font-size: $s-size;
  }

  #nav-dropdown-search {
    color: $header-bg;
    font-size: $s-size;
  }

  #search-button {
    cursor: pointer;
    background-color: $primary_color;
    color: white;
  }

  .input-group-text {
    border: none;
  }

  .cart {
    margin-left: 25px;
    margin-top: 8px;

    .custom-badge {
      background: $primary_color;
      border-radius: 50%;
      color: #fff;
      font-size: 13px;
      padding: 0px 5px;
      position: absolute;
      margin: -15px 0px 0px -15px;
    }
  }
}

.dropdown-menu {
  --bs-dropdown-link-active-bg: none !important
}

.searchbar{
  .input-group-text{
    background-color: $primary_color;
  }
  .search_cat_dropdown{
    font-size: 14px;
  }
}
h5{
  margin-bottom: none !important;
}