:root {
    --hue: 223;
    --bg: hsl(var(--hue), 10%, 90%);
    --fg: hsl(var(--hue), 10%, 10%);
    --trans-dur: 0.3s;

}


.preloader{
    color: $primary_color;
}
// .preloader{
//     position: fixed;
//     display: flex;
//     justify-content: center;
//     top: 50%;
//     width: 100%;
//     z-index: 80;
//     font-size: 18px;
// }

// .css-df17o1 {
//     height: 100vh;
//     position: fixed;
//     border: 0px;
//     transition: none;
//     top: 0px;
//     bottom: 0px;
// }

// .preloader__text {
//     position: relative;
//     height: 1.5em;
// }

// .preloader__msg {
//     animation: msg 0.3s 13.7s linear forwards;
//     position: absolute;
//     width: 100%;
// }

// .preloader__msg--last {
//     animation-direction: reverse;
//     animation-delay: 14s;
//     visibility: hidden;
// }

// .loader_cart {
//     display: block;
//     margin-bottom: 10px;
//     width: 3em;
//     height: 3em;
// }

// .cart__lines,
// .cart__top,
// .cart__wheel1,
// .cart__wheel2,
// .cart__wheel-stroke {
//     animation: cartLines 2s ease-in-out infinite;
// }

// .cart__lines {
//     stroke: $primary_color;
// }

// .cart__top {
//     animation-name: cartTop;
// }

// .cart__wheel1 {
//     animation-name: cartWheel1;
//     transform: rotate(-0.25turn);
//     transform-origin: 43px 111px;
// }

// .cart__wheel2 {
//     animation-name: cartWheel2;
//     transform: rotate(0.25turn);
//     transform-origin: 102px 111px;
// }

// .cart__wheel-stroke {
//     animation-name: cartWheelStroke
// }

// .cart__track {
//     transition: stroke var(--trans-dur);
// }

// /* Dark theme */
// @media (prefers-color-scheme: dark) {
//     :root {
//         --bg: hsl(var(--hue), 10%, 10%);
//         --fg: hsl(var(--hue), 10%, 90%);
//     }

//     .cart__track {
//         stroke: white;
//     }
// }

// /* Animations */
// @keyframes msg {
//     from {
//         opacity: 1;
//         visibility: visible;
//     }

//     99.9% {
//         opacity: 0;
//         visibility: visible;
//     }

//     to {
//         opacity: 0;
//         visibility: hidden;
//     }
// }

// @keyframes cartLines {

//     from,
//     to {
//         opacity: 0;
//     }

//     8%,
//     92% {
//         opacity: 1;
//     }
// }

// @keyframes cartTop {
//     from {
//         stroke-dashoffset: -338;
//     }

//     50% {
//         stroke-dashoffset: 0;
//     }

//     to {
//         stroke-dashoffset: 338;
//     }
// }

// @keyframes cartWheel1 {
//     from {
//         transform: rotate(-0.25turn);
//     }

//     to {
//         transform: rotate(2.75turn);
//     }
// }

// @keyframes cartWheel2 {
//     from {
//         transform: rotate(0.25turn);
//     }

//     to {
//         transform: rotate(3.25turn);
//     }
// }

// @keyframes cartWheelStroke {

//     from,
//     to {
//         stroke-dashoffset: 81.68;
//     }

//     50% {
//         stroke-dashoffset: 40.84;
//     }
// }

// .custom-spinner{
//     width: 3.2rem !important;
//     height: 3.2rem !important;
//     color:$primary_color !important;
//     --bs-spinner-border-width:0.15rem !important
// }
