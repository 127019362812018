.mobilecheckout {
    margin-bottom: 2rem;

    .mobile-checkout-divider {
        border-radius: 0;
        padding: 15px;
        border: none;
        margin: 0;
        border-bottom: 8px solid #f5f5f5;
    }

    .delivery_Address {
        .delivery_Address-header {
            display: flex;
            justify-content: space-between;
            font-weight: 700;
            align-items: center;
            margin-bottom: 10px;

            .change {
                border: 2px solid $primary_color;
                border-radius: 5px;
                color: #000;
                cursor: pointer;
                font-size: 14px;
                font-weight: 500;
                padding: 1px;
                text-align: center;
                width: 30%;
            }
        }

        .delivery_Address-name {
            margin-bottom: 5px;
            font-weight: 500;
        }

        .delivery_Address-line2 {
            font-size: 14px;
            -webkit-box-orient: vertical;
            display: -webkit-box;
            max-width: 100%;
            overflow: hidden;
            -webkit-line-clamp: 3;
            text-overflow: ellipsis;
        }

    }

}

.custom-checkout-address-modalsheet {
    border-top-right-radius: 0px !important;
    border-top-left-radius: 0px !important;
    top: 0px !important;
    height: auto !important;
    overflow-y: scroll;

    // bottom: unset !important;
    // height: 850px !important;
    .question {
        overflow-y: scroll;
    }

    .accordion-address-body-listitem {
        .deliver-address-btn {
            .product-detail-button {
                width: 100% !important;
                margin-left: unset;
            }
        }
    }

    .custom-addresslist-bottom-btn {
        box-shadow: none;
        border-top: 1px solid #e0e0e0;

        .product-detail-buttongroup {
            .product-detail-button {
                background: none;
                border: 1px solid $primary_color;
                border-radius: 5px;
                color: $black;
            }
        }
    }
}

.address-custom-header {
    // height: 57px;

    .address-custom-header-top {
        margin-top: 11px;
        margin-bottom: 11px;

        .custom-header-top-title {
            color:$header-text-color;
            font-size: $s-size0;
            margin-top: -1px;
            margin-left: 15px;
        }
    }
}

.mobile-checkout-selectslot-heading {
    border: 2px solid $primary_color;
    padding: 7px 10px 5px 10px;
    border-radius: 5px;
    display: flex;
    font-size: 14px;
    font-weight: 500;
    justify-content: space-between;

    .mobile-checkout-selectslot-text {}
}

.mobile-delivery-margin20 {
    margin-top: -10px;
}

.mobile-timelist {
    .mobile-timeitem {
        padding: 10px;
        border-bottom: 2px solid #f5f5f5;
    }
}

.mobile-timeitem-active{
    font-weight: 500;
    border-left: 3px solid #ff9933;
}

.mobile-checkout-divider1 {
    border-radius: 0;
    border: none;
    margin-top: 20px;

    border-bottom: 5px solid #f5f5f5;
}

.mobile-timeitem-right {
    border: 1px solid $primary_color;
    text-align: center;
    float: right;
    width: 20%;
    font-size: 16px;
}

.mobile-checkout-deliheading{
    font-weight: 700;
    margin-bottom: 10px;
}