.mobile-filters-header {
    position: fixed;
    top: 57px;
    background: rgb(255, 255, 255);
    width: 100%;
    padding: 10px 0px 0px 0px;
    z-index: 11;
    transition: top 0.6s ease 0s;
    

    .sort-filter-options {
        display: flex;
        // justify-content: space-between;
        -webkit-box-align: center;
        align-items: center;
        list-style: none;
        padding: 0px;
        // margin-bottom: 16px;
        margin-bottom: 5px;
        height: 30px;
        background: rgb(255, 255, 255);

        .__sort-filter-options_item {
            cursor: pointer;
            // width: 100%;
            height: 100%;
            display: flex;
            -webkit-box-align: center;
            align-items: center;
            -webkit-box-pack: center;
            justify-content: center;
            position: relative;
            overflow: hidden;
            // padding: 4px;
            padding: 8px;
            border: 1px solid #e0e0e0;

            .content {
                padding-left: 4px;
                color: rgb(53, 53, 67);
                font-style: normal;
                font-weight: 600;
                font-size: 15px;
                margin: 0px;
                padding: 0px;
            }
        }
    }
}

.custom-filter-modalsheet {
    height: auto !important;
    border-top-left-radius: 25px !important;
    border-top-right-radius: 25px !important;

    .custom-filter-sheet-haeder {
        padding: 15px 15px 10px 15px;
        display: flex;
        font-size: $s-size;
        border-bottom: 1px solid #eee;
        justify-content: space-between;
    }

    .filter-body {
        display: flex;
        height: calc(90vh - 122px);
        background-color: rgb(255, 255, 255);
        border-radius: 0px;

        .filter-body-leftside {
            flex: 1 1 50%;
            overflow-y: scroll;
            background: rgb(234, 234, 242);

            .fOzBOt {
                width: 100%;
                position: relative;
                padding: 16px 12px 16px 7px;
                color: rgb(97, 97, 115);
                background: rgb(234, 234, 242);
                font-size: 13px;
                border-bottom: 1px solid rgb(206, 206, 222);
            }

            .active-category {
                background: rgb(255, 255, 255);
                color:$primary_color;
                font-weight: bold;
                border-left: 4px solid $primary_color;
            }
        }

        .filter-body-rightside {
            flex: 1 1 66%;
            overflow-x: scroll;
            margin: 14px
        }
    }
}

.mobile-filter-apply-btn {
    .product-detail-button {
        font-size: $s-size;
        padding: 5px;
    }

}

.sort-title{
    font-size: 16px;
    font-weight: 600;
}

.mobileFilter-shortBy{
    overflow-y: scroll;
    margin-bottom: 10px;
    .mobileFilter-shortBy-list{
       padding: 6px 10px;
        .mobileFilter-shortBy-list-radio{
           
            border-width: 3rem;
        }
        .mobileFilter-shortBy-list-content{
            padding-left: 10px;
            font-size: 16px;
        }
    }
}