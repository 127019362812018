/*product card container*/
.mycategorySwiper1 {
    @media only screen and (min-width: 768px) {
        margin-left: 10px;
        margin-right: 10px;
        // margin-top: 15px;
        margin-bottom: 10px;
    }
}

.mycategorySwiper2 {
    @media only screen and (min-width: 768px) {
        margin-left: 50px;
        margin-right: 50px;
        // margin-top: 15px;
        margin-bottom: 20px;
    }

}

.custom-card-main {
    position: relative;
    margin-top: -150px;
    z-index: 1;


}

.carousel-main-div {
    position: relative;
}

.home-card-component {
    padding: 5px;

    .card-product {
        background-color: white;
        display: flex;
        margin-bottom: 25px;
        flex-direction: column;
        justify-content: space-between;
        // box-shadow: -10px -0px 20px rgba(196, 240, 240, 0.5),
        //     50px 50px 20px rgba(196, 240, 240, 0.5);
        padding: 10px;

        @media only screen and (min-width: 991px) {
            height: 450px;
        }

        @media only screen and (max-width: 500px) {
            margin-bottom: 10px;
            padding-top: 10px;
            padding-left: 5px;
            padding-right: 5px;
            padding-bottom: 0px;
        }

        .card-product-title {
            font-size: $s-size0;
            font-weight: 700;
            padding: 0 0px 0px 10px;
            white-space: nowrap;
            text-overflow: ellipsis;
            overflow: hidden;

            @media only screen and (max-width: 470px) {
                font-size: 14px;
            }
        }

        .card-product-nested-card {
            display: flex;
            flex-wrap: wrap;
            padding: 0 10px 10px 10px;
            justify-content: space-between;

            .card-main {
                padding: unset !important;
                padding: 0px 5px;

            }

            .card-nested {
                margin-bottom: 15px;
                margin: 5px;
                border: 1px solid #eee;
                cursor: pointer;

                img {
                    width: 100%;
                    height: 140px;
                    object-fit: contain;
                    // border: 1px solid #eee;



                }

                @media only screen and (max-width: 500px) {
                    img {
                        height: auto;
                    }


                }

                .card-nested-title {
                    font-size: 14px;
                    text-overflow: ellipsis;
                    overflow: hidden;
                    white-space: nowrap;
                    text-align: start;
                    margin-top: 3px;
                    padding: 0px 5px;
                }
            }
        }

        .card-product-btn {
            font-size: 14px;
            cursor: pointer;
            padding: 0 10px 10px 10px;
            color: $primary_color
        }
    }
}

.home-carousel-component-header.detail-carousel-bottom {
    margin-left: unset !important;
    margin-top: unset !important;
}


.home-carousel-component-header {
    margin-left: 20px;
    margin-top: 20px;
    justify-content: space-between;
    margin-right: 22px;
    margin-bottom: 10px;
    // .myotherclass {
    //     @extend .myclass;
    //     color: #000000;
    //   }

    @media only screen and (max-width: 576px) {
        margin-right: 10px;
    }

    display: flex;
    align-items: center;

    @media only screen and (max-width: 576px) {
        margin-top: 10px;
    }

    .home-carousel-componentheader-title {
        font-size: $m-size;
        font-weight: 700;

        @media only screen and (max-width: 576px) {
            font-size: $s-size;
        }
    }

    .home-carousel-componentheader-viewall {
        font-size: $s-size0;

        @media only screen and (max-width: 576px) {
            font-size: $s-size;
        }

        color: $primary_color;
        cursor: pointer;
        font-weight: 500;
    }
}

.home-carousel-bg-wihte {
    background: white;
    position: relative;
    // z-index: -1;

    .customprevious,
    .ourprev {
        position: absolute;
        top: 45%;
        z-index: 10;
        // border: 1px solid $primary_color;
        background: #fff;
        border-radius: 5px;
        padding: 5px;
        left: 5px;
        margin-left: 5px;
        color: $primary_color;
        // border-radius: 100%;
        width: 25px;
        height: 25px;
        display: flex;
        align-items: center;
        justify-content: center;
    }

    .ourprev {
        margin-left: 15px;
    }

    .customprevious-brands {
        position: absolute;
        // top: 230px;
        border: 1px solid $primary_color;
        border-radius: 5px;
        top: 60%;
        padding: 5px;
        left: 5px;
    }

    // .customnextdetail {
    //     position: absolute;
    //     top: 45%;
    //     right: 12px;
    //     border: 1px solid $primary_color;
    //     border-radius: 5px;
    //     padding: 5px;
    // }

    .customnext,
    .customnextdetail,
    .ournext {
        position: absolute;
        top: 45%;
        z-index: 1;
        right: 12px;
        // border: 1px solid $primary_color;
        background: #fff;
        border-radius: 5px;
        padding: 5px;
        color: $primary_color;
        // border-radius: 100%;
        width: 25px;
        height: 25px;
        display: flex;
        align-items: center;
        justify-content: center;
        margin-left: 5px;
    }

    .ournext {
        margin-right: 10px;
    }

    .customnext-brands {
        position: absolute;
        // top: 230px;
        top: 60%;
        right: 12px;
        border: 1px solid $primary_color;
        border-radius: 5px;
        padding: 5px;
    }
}

.carousel-componet-card {
    margin: 10px 5px;

    // border: 1px solid black;
    // border: 1px solid red;
    // box-shadow: 0 3px 16px 0 #0000001c;
    // padding: 20px 0px 20px 20px;
    .home-product-card {
        // border: 1px solid green;
        // border-radius: 5px;
        // margin: 17px;
        height: 100%;
        box-shadow: 0 3px 16px 0 #0000001c;
        cursor: pointer;
        font-family: $font-family-2;


        // background: white;
        // width: 170px;
        // height: auto;
        .home-product-title-maindiv {
            // padding: 0px 5px;
        }

        .home_product_image {
            overflow: hidden;
            position: relative;


            img {
                display: block;
                width: 100%;
                aspect-ratio: $app-aspect-ratio;
                // height: 100%;
                // max-height: 130px;
                object-fit: contain;
            }

            .img-half-carousel {
                width: 100%;
                height: 124px;
                object-fit: contain;
                // max-height: 130px;
            }

            @media only screen and (max-width: 500px) {
                .img-half-carousel {
                    height: auto;
                }
            }

            .percentages-on-products {
                position: absolute;
                top: 0;
                left: 10px;
            }
        }

        .home_produt_image_extracss {
            // @media only screen and (max-width: 991px) {
            //     height: 190px;
            // }
            // @media only screen and (min-width: 991px) {
            //     height: 250px;
            // }

        }



        .home-product-card-title {
            font-size: 14px;
            margin-top: 10px;
            height: 50px;
            font-weight: 400;
            color: $paragraph-color;
            padding: 0px 10px;
            font-family: $font-family-2;
            display: -webkit-box;
            -webkit-line-clamp: 3;
            /* Number of lines to show */
            -webkit-box-orient: vertical;
            overflow: hidden;
            text-overflow: ellipsis;
        }

        @media only screen and (max-width: 500px) {
            .home-product-card-title {
                padding: 0px 0px;
            }
        }

        .pricetag {
            @extend .home-product-card-title;
            font-weight: 700;
            margin-top: 2px;
        }

        .product-price {
            align-items: center;
            display: flex;
            padding: 5px;
            // margin-top: 2px;


            .Paragraph-16-M--Semibold {
                font-size: $s-size;
                display: inline;
                color: $black;
                font-family: $font-family-2;
                font-weight: 700;
            }

            .offer-revamp {
                color: $paragraph-color;
                display: inline-block !important;
                margin-left: 7px;
                font-size: $xs-size;
                font-family: $font-family-2;

                @media only screen and (max-width: 1300px) {
                    font-size: $xs-size;
                }

                text-decoration: line-through;
            }

            .offer-label-revamp {
                font-size: $xs-size;

                @media only screen and (max-width: 1300px) {
                    font-size: $xs-size;
                }

                // padding: 0px 3px;
                margin-left: 8px;
                margin-top: 5px;
                color: $primary_color;
                font-weight: 600;
            }
        }

        @media only screen and (max-width: 500px) {
            .product-price {
                padding: 0px 0px;
            }
        }
    }


}

.half-carousel-card {
    // border: 1px solid #eee;
    // margin-bottom: 10px;
    // border-radius: 5px;


    @media only screen and (max-width: 991px) {
        margin-bottom: unset;
    }

    .home-product-card {
        img {
            height: auto;
        }
    }

}



// @media only screen and (max-width: 1400px) {
//     .custom-card-main {
//         margin-top: -150px;
//     }
// }

// @media only screen and (max-width: 1200px) {
//     .custom-card-main {
//         margin-top: -230px;
//     }
// }

// @media only screen and (max-width: 991px) {
//     .custom-card-main {
//         margin-top: -190px;
//     }
// }

// @media only screen and (max-width: 791px) {
//     .custom-card-main {
//         margin-top: -160px;
//     }
// }

@media only screen and (max-width: 991px) {
    .custom-card-main {
        margin-top: -37px;
    }
}

@media only screen and (max-width: 570px) {
    .custom-carousel-indicators {
        bottom: -45%;
    }

    .home-card-component {
        padding: 10px;
        padding-bottom: 40px;
    }
}

@media only screen and (max-width: 500px) {
    .custom-card-main {
        margin-top: -80px;
    }

    .custom-carousel-indicators {
        bottom: -45%;
    }
}

@media only screen and (max-width: 400px) {
    .custom-card-main {
        margin-top: -80px;
    }

    .custom-carousel-indicators {
        bottom: -45%;
    }
}

.swiper-button-disabled {
    opacity: .2;
    // pointer-events: none;
}

.home-halfbanner {

    img {
        width: 100%;
        height: 450px;
        object-fit: cover;

        @media only screen and (max-width: 576px) {
            height: auto;
            margin-bottom: 10px;
        }

    }
}

.home-fullbanner {
    img {
        width: 100%;
        // border-radius: 10px;
    }
}

.home-card-row {
    @media only screen and (max-width: 991px) {
        margin-left: -2px !important;
        margin-right: 0px !important;
    }
}

.home-card-col {
    cursor: pointer;

    @media only screen and (min-width: 991px) {
        padding-right: 10px !important;
        padding-left: 0px !important;
    }

    @media only screen and (max-width: 991px) {
        padding-top: 10px !important;
        padding-right: 0px !important;
        padding-left: 4px !important;
    }
}

.home_carousel_img {
    width: 100% !important;
    // opacity: 0.1;
    // aspect-ratio: 5.5/2.5;
    @media only screen and (min-width: 576px) {
        // aspect-ratio: 3/2.5;
    }

    @media only screen and (min-width: 576px) {
        // height: 600px;
        object-fit: contain;
    }
}

.appprimary_color {
    // border: 1px solid $primary_color !important;
    color: $primary_color !important;

    .product-detail-btn-icon {
        color: $primary_color !important;
    }

}

.brands-col-maindiv {
    width: 100%;

    padding: 10px 10px !important;

    .single-banner {
        position: relative;
        cursor: pointer;
        width: 100%;

        .brand-images {
            width: 100%;
            object-fit: cover;
        }
    }

}

.brand32-main {
    display: flex;
}

.dfdbhgh {
    border: 1px solid black;
}

.review-prevbutton {
    z-index: none;
    z-index: 1 !important;
}











// .single-banner::before {
//     border-top: 2px solid #fff;
//     // border-bottom: 2px solid #fff;
//     // border-right: 2px solid #fff;

//     content: "";
//     left: 15px;
//     opacity: 0;
//     right: 100%;
//     position: absolute;
//     top: 15px;
//     // -webkit-transition: all 600ms ease 0s;
//     // transition: all 600ms ease 0s;
//     // z-index: 1;
// }

// .single-banner::before {
//     // border-top: 2px solid #fff;
//     border-bottom: 2px solid #fff;
//     // border-right: 2px solid #fff;

//     content: "";
//     left: 15px;
//     opacity: 0;
//     right: 100%;
//     position: absolute;
//     bottom: 18px;
//     -webkit-transition: all 600ms ease 0s;
//     transition: all 600ms ease 0s;
//     // z-index: 1;
// }

// .single-banner:hover:before {
//     opacity: 1;
//     right: 15px;
// }

// .single-banner::after {
//     border-right: 2px solid #fff;
//     bottom: 19px;
//     content: "";
//     left: 15px;
//     opacity: 0;
//     position: absolute;
//     // top: 15px;
//     -webkit-transition: all 600ms ease 0s;
//     transition: all 600ms ease 0s;
//     -webkit-transition: all 0.5s ease-out;
// }

// .single-banner::after {
//     border-left: 2px solid #fff;

//     content: "";
//     right: 15px;
//     opacity: 0;
//     position: absolute;
//     top: 100%;
//     -webkit-transition: all 600ms ease 0s;
//     transition: all 600ms ease 0s;
//     // -webkit-transition: all 0.5s ease-out;
// }

// .single-banner:hover:after {
//     opacity: 1;
//     top: 15px;
// }




.brand4-col-first {
    padding: 20px 0px !important;
}

.brand4-twoscreen-third-image {
    padding-bottom: 10px;
}

.brand4-twoscreen-fourth-image {
    padding-top: 10px;
}

.brand4-threescreen-second-image {
    padding-bottom: 12.5px;
}








// .................................offcarousel.....................
.offcarousel-main-row {
    padding: 12px;

    .offcarousel-column {
        padding: 5px 15px;

        // margin:0px 10px ;
        // border: 1px solid red;
        .offcarousel-image-div {
            width: 100%;
            // margin-bottom: 5px;
            display: flex;
            justify-content: center;
            align-items: center;
            text-align: center;

            // margin-bottom: 45px;
            .offcarousel-image {
                width: 100%;
            }
        }

        .offcarousel-column-carousel {
            // margin-bottom: 45px;

        }
    }
}




.offcarousel-swiper {
    margin-top: 0px !important;
}

.offcarousel-image-height {
    aspect-ratio: unset !important;
    aspect-ratio: 2.6/2.6 !important;
}









// .................................latest carouse...............................
.latest-swiper-image-title {
    overflow: hidden;
    text-overflow: ellipsis;
    // white-space: nowrap;
    line-height: 5px;
    display: -webkit-box;
    -webkit-line-clamp: 3;
    -webkit-box-orient: vertical;



}

.latest-div {
    margin-bottom: 20px;
}

.latest-button-readmore {
    padding: 10px;
    border-radius: 5px;
    width: fit-content;
    display: flex;
    justify-content: center;
    align-items: center;
    border: 1px solid lightgrey;
    font-family: $font-family-2;
    color: $paragraph-color;
    cursor: pointer;
}

.latest-button-readmore:hover {
    background: $primary_color;
    color: $white;
}

.latest-images {
    aspect-ratio: none !important;
    aspect-ratio: 4/3 !important;
}

.latest-readmore-button {
    margin-right: 10px;
    font-family: $font-family-2;
    color: $paragraph-color;
}

// .latest-readmore-button:hover{
//     color: $white;
// }



// ............................bestSeller-products.............
.bestSeller-products {
    display: inline;

}








// .................carouselsample home...................
@media only screen and (min-width:991px) {
    .carousel-images {
        height: 500px !important;
    }
}

.carousel-images {}

.swiper-pagination-clickable .swiper-pagination-bullet {
    background: white;
    margin-bottom: 50px;

}

.swiper-pagination-clickable .swiper-pagination-bulletactive {
    width: 25px;
    background: orange;
}

@media only screen and (min-width:576px) {

    .swiper-pagination-fraction,
    .swiper-pagination-custom,
    .swiper-horizontal>.swiper-pagination-bullets,
    .swiper-pagination-bullets.swiper-pagination-horizontal {
        margin-bottom: 50px !important;
    }
}

@media only screen and (max-width:576px) {

    .swiper-pagination-fraction,
    .swiper-pagination-custom,
    .swiper-horizontal>.swiper-pagination-bullets,
    .swiper-pagination-bullets.swiper-pagination-horizontal {
        margin-bottom: 10px !important;
    }
}

.carouselsample-swiper {
    position: relative !important;
    // border: 1px solid black;
}

.ourproducts-swiper {}

.carouselsample-content {
    color: white;
    position: absolute !important;
    right: 5%;
    top: 25%;
    left: 5%;
    z-index: 1;

}

.carousel-sample-button {
    font-family: $font-family-2;
    color: $white;
    width: fit-content;
    padding: 15px 20px;
    font-size: 14px;
    background: $primary_color;
    cursor: pointer;
}

@media only screen and (max-width:450px) {
    .carouselsample-content {
        color: white;
        position: absolute !important;
        right: 5%;
        top: 20%;
        left: 5%;
        z-index: 1;

    }

    .carousel-sample-button {

        padding: 10px 10px;

    }

    .carouselsample-main-title {
        font-size: 20px;
    }

    .carouselsample-sub-title {
        font-size: 16px;
    }

    .carouselsample-pargraph-content-title {
        font-size: 10px;
    }

}








.carouselsample-main-title {
    -webkit-animation: 1200ms ease-in-out 0s normal none 1 running zoomInRight !important;
    animation: 1600ms ease-in-out 0s normal none 1 running zoomInRight !important;
}

.owl-item.active .animation-style-02 .slider-content span {
    -webkit-animation: 800ms ease-in-out 0s normal none 1 running zoomInRight;
    animation: 800ms ease-in-out 0s normal none 1 running zoomInRight;
}

.owl-item.active .animation-style-02 .slider-content .product-offer {
    -webkit-animation: 800ms ease-in-out 0s normal none 1 running zoomInUp;
    animation: 800ms ease-in-out 0s normal none 1 running zoomInUp;
}

.owl-item.active .animation-style-02 .slider-content h1 {
    -webkit-animation: 1200ms ease-in-out 0s normal none 1 running zoomInUp;
    animation: 1200ms ease-in-out 0s normal none 1 running zoomInUp;
}

.owl-item.active .animation-style-02 .slider-content h2 {
    -webkit-animation: 1600ms ease-in-out 0s normal none 1 running zoomInUp;
    animation: 1600ms ease-in-out 0s normal none 1 running zoomInUp;
}

.owl-item.active .animation-style-02 .slider-content p {
    -webkit-animation: 2000ms ease-in-out 0s normal none 1 running zoomInUp;
    animation: 2000ms ease-in-out 0s normal none 1 running zoomInUp;
}

.fdghdgdkj.active .carousel-sample-button.active {
    // -webkit-animation: 2500ms ease-in-out 0s normal none 1 running zoomInUp;
    animation: 2500ms ease-in-out 0s normal none 1 running zoomInUp;
}











// .......................auticomplete........................
.autocomplete-inputbox-maiv-div {
    display: flex;
    // flex-direction: column !important;
    justify-content: center;
    align-items: center;



}

.autocomplete-inputbox-sub {
    border: 1px solid gray;
    width: 100% !important;

}

.autocomplete-inputbox-main {
    position: relative !important;
}

.autocomplete-inputbox {
    // width: 50% !important;
    border: none !important;
    outline: none;
    padding: 20px 40px;
    border-radius: 5px;
    border: 1px solid $border_color !important;
}

.autocomplete-dropdown-container {
    position: absolute;
    width: 100%;
    z-index: 10;
    background: $white;
    cursor: pointer;
    padding: 0px 10px;
}

.autocomplete-inputbox-searchbox {
    position: absolute;
    left: 10px;
    top: 27%;
    font-size: 21px;
}

.autocomplete-inputbox-wrong {
    position: absolute;
    right: 10px;
    top: 27%;
    font-size: 21px;
}

.fjdfdjh {
    font-size: 14px;
    font-weight: 500;
    font-family: $font-family-2;
    padding: 3px 0px;

}

.fjdfdjhgfh {
    font-size: 12px;

    font-family: $font-family-2;
    // padding: 5px 0px;
    color: gray;
}

.fnhdfdgj {
    padding-bottom: 40px !important;
}

.home-custom-container .carousel-control-next {
    @media only screen and (min-width: 991px) {
        // top: -140px
        margin-bottom: 120px;
    }
   
}

.home-custom-container .carousel-indicators {
    .active {
        background: $primary_color !important;
        border: 2px solid $primary_color !important;
    }

   
    @media only screen and (min-width: 768px) {
        bottom: 40px
    }

    @media only screen and (min-width: 991px) {
        bottom: 140px
    }
}

.home-custom-container .carousel-control-prev {
    @media only screen and (min-width: 991px) {
        // top: -140px
        margin-bottom: 120px;
        // top: 0px;
    }
   
}



.home-carousel-banner-content {
    position: absolute;
    top: 10%;
    left: 20%;

    @media only screen and (max-width:576px) {
        position: absolute;
        top: 20%;
        left: 5%;
    }
}

.homebanner-title {
    font-size: 35px;
    font-weight: 800;
    color: #fff;

    @media only screen and (max-width:576px) {
        font-size: 22px;
        font-weight: 600;
        color: #fff;
    }
}

.homebanner-discription {
    font-size: 18px;
    font-weight: 500;
    color: #fff;

    @media only screen and (max-width:576px) {
        font-size: 10px;
        font-weight: 500;
        color: #fff;
    }
}

.homebanner-button {
    font-size: 12px;
    font-weight: 500;
    margin-top: 30px;
    margin-left: 20px;
    color: #fff;
    width: fit-content;
    padding: 5px 10px 5px 20px;
    text-transform: uppercase;
    border-radius: 15px;
    background: $primary_color;
    position: relative;

    @media only screen and (max-width:576px) {
        font-size: 10px;
        margin-top: 5px;
        margin-left: 20px;
        padding: 5px 10px 5px 20px;
    }
}

.homebanner-cart-symbol {
    width: 20px;
    height: 20px;

    // border: 1px solid black;


    @media only screen and (max-width:576px) {
        font-size: 30px;
        left: -18px;
        bottom: -2px;
        padding: 2px;
    }
}

.homebanner-cart-main-div {
    // border-radius:100%;
    // font-size: 20px;
    padding: 10px;
    background: #fff;
    color: $primary_color;
    left: -22px;
    bottom: -5px;
    position: absolute;
    @media only screen and (max-width:576px) {
        padding: 7px;
        left: -20px;
    }
}













