.search {
    border-bottom: 1px solid #eee;
    display: flex;
    position: relative;
    padding-top: 7px;
    padding-bottom: 7px;
    .search-left-icon {
        margin-left: 16px;
        margin-top: 13px;
        margin-right: 10px;
    }

    .custom-search-input {
        width: 100%;
        border: none;
        outline: none;
        padding: 12px 12px 12px 0px;
    }

    .custom-search-input::placeholder {
        font-size: $xs-size;
    }

    .custom-search-inputicon {
        color: #ff9933;
        cursor: pointer;
        margin-top: 13px;
        margin-right: 16px;
    }
}

.listElements {
    padding: 8px 16px;

    .listElement-title {
        font-size: $xs-size;
    }

    ul {
        list-style-type: none;
        padding: unset;


        li {
            white-space: nowrap;
            overflow: hidden;
            text-overflow: ellipsis;
            max-width: 100%;
            display: inline-block;
            font-size: $xs-size;
            margin: 3px;
            letter-spacing: -0.005em;
            padding: 4px 12px !important;
            box-shadow: 0 0.5px 1.5px rgba(0, 0, 0, .25882);

            a {
                text-decoration: none;
            }
        }

    }
}