.Razzerpay{
    margin-top: 20px;
}
.payment-razerpay-main{
    display: flex;
    flex-direction: column;
    // justify-content: ;
    // align-items: center;
    // border: 1px solid $border_color;
    // padding: 5px;
    border-radius: 5px;
    font-size: 14px;
    margin-top: 10px;
    // border-bottom: 1px solid $border_color;
}
.payment-radio{
    transform: scale(1.5);
    margin: 4px 0px 0px 5px;
}
.payment-discription{
    display: inline !important;
    flex-direction: row !important;
    justify-content: center !important;
    align-items: center !important;
    text-align: center;
    border-bottom: 1px solid $border_color;
    padding: 0px 20px;
}
.payment-icon{
    font-size: 100px;
    color: gray;
    //   transform: scale(1.5);
}
.icons-radio.active{
    border: 1px solid green;
}
.icons-radio{
    padding: 10px;
   
    border-bottom: 1px solid $border_color;
    // display: flex;
    // // justify-content: center;
    // align-items: center;
    // text-align: center;
}
.razzerpay-subdiv{
    display: flex;
    // justify-content: center;
    // align-items: center;
    // text-align: center;
}
.razzerpay-title{

}
.razzer-img{
    margin-right: 2px;
}