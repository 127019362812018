/* Styles for .lines */
.lines {
  display: flex;
  align-self: center;
  list-style: none;
  margin: 0;
  padding: 0;
}

.lines li {
  width: 10px;
  height: 22px;
  margin: 0 5px;
  position: relative;
}

.lines li .lines-item {
  height: 100%;
  width: 100%;
  display: block;
}

.lines li .lines-dash {
  border-radius: 5px;
  border-color: transparent;
  background-color: aliceblue;
  max-width: 50px;
  height: 5px;
  /* transition: background 3000ms ease; */
}

.lines li.active .lines-dash:before {
  content: '';
  display: block;
  border-color: transparent;
  border-radius: 5px;
  height: 5px;
  width: 0;
  background: #ffcc00;

  animation: progressBar 3000ms linear forwards;
}

@keyframes progressBar {
  0% {
    width: 0;
  }

  100% {
    width: 100%;
  }
}

.custom-carousel-indicators {
  display: flex;
  justify-content: center;
  position: absolute;
  z-index: 1;
  left: 50%;
  right: 50%;
  bottom: 50%;
  top: 30%;

  @media only screen and (min-width: 768px) {
    top: 64%;
  }
}