.buisness-register-container {
    margin: auto;
    // width: 50%;
    margin-top: 70px;

    .register-wrap-container {
        justify-content: center;
        .buisnessregitser-left-side,
        .buisnessregitser-right-side {
            border: 1px #ddd solid;
            padding: 2rem;
            background: #fff;
            border-radius: 5px;
            -webkit-box-shadow: 0px 10px 34px -15px rgba(0, 0, 0, 0.24);
            -moz-box-shadow: 0px 10px 34px -15px rgba(0, 0, 0, 0.24);
            box-shadow: 0px 10px 34px -15px rgba(0, 0, 0, 0.24);

            img {
                max-height: 150px;
                object-fit: contain;
            }

            h4 {
                font-weight: 700;
            }

            h4 span {
                font-size: 16px;
                display: block;
                margin-top: 10px;
                font-weight: 400;
                color: rgba(0, 0, 0, 0.4);
            }

            
        }

        .signupsec {
            margin-top: 15px;
            margin-bottom: 20px;
            display: flex;
            justify-content: center;

            .DontAccount {
                color: #828282;
                font-size: 12px;
                font-weight: 400;
            
            }

            .signUpNavLink {
                color: #0055b8;
                font-family: Poppins, sans-serif;
                font-size: 12px;
                font-weight: 600;
             
                // margin-top: 20px;
                margin-left: 5px;
                text-decoration: none;
            }
        }

        .navlink-2 {
            margin-top: 0px;
        }

        .buisnessregitser-left-side {
           height: 100%;
        }
    }
}

.register-btn {
    margin-left: 0px ;
    justify-content: center;

    .product-detail-button {
        margin-top: 15px !important;
        width: 25% ;
    }
}

@media only screen and (max-width: 991px) {
    .buisness-register-container {
        width: auto;
        margin: 20px;
        border: 1px #ddd solid;
        -webkit-box-shadow: 0px 10px 34px -15px rgba(0, 0, 0, 0.24);
        -moz-box-shadow: 0px 10px 34px -15px rgba(0, 0, 0, 0.24);
        box-shadow: 0px 10px 34px -15px rgba(0, 0, 0, 0.24);
        .product-detail-button {
            width: 100% !important;
        }

        .buisnessregitser-left-side{
            padding-bottom: unset !important;
        }

        .buisnessregitser-right-side {
            padding-top: unset !important;
        }

        .register-btn {
            
        }
    
        .buisnessregitser-left-side,.buisnessregitser-right-side {
            padding-left: 1rem !important;
            padding-right: 1rem !important;
            padding-bottom: 1rem !important;
            border: none !important;
            box-shadow: none !important;
        }
    }

    

}