.orderDetailsrow{
    padding-top: 10px;
     .expectedDate{
         font-size: 25px;
         font-weight: 600;
       
     
     }
     .expectedDate-Arriveddate{
         font-size: 20px;
          color: #ff9933;
        margin-bottom: 20px;
     
     }
     .successOrder-Products{
         border: 1px solid #dbdbdb;
         display: flex;
         .successOrder-Products-image{
             width: 100px;
             height: 100px;
         }
         .successOrder-Products-Details{
           padding: 5px 0px 0px 15px ;
           
             .successOrder-Products-Details-productName{
                 
                 font-weight: 500;
                 overflow: hidden;
                 text-overflow: ellipsis;
                 white-space: initial;
                 display: -webkit-box;
                 font-size: 14px;
                 -webkit-line-clamp: 1;
                 -webkit-box-orient: vertical;
             }
             .successOrder-Products-Details-productcolor{
                 font-size: 14px;
             }
             .successOrder-Products-Details-productPrice{
                 font-size: 14px;
                 font-weight: 500;
     
             }
             .successOrder-Products-Details-productOffer{
                 font-size: 14px;
                 font-weight: 500;
                 
                 display: flex;
                 .successOrder-Products-Details-productOffer-count{
                     padding-right: 5px;
                 }
                 .successOrder-Products-Details-productOffer-head{
                     padding-right: 5px;
                 }
             }
         }
       
     }
     .successOrder-ProductsBTN{
         display: flex;
         flex-direction: row;
         padding-top: 40px;
         width: 50%;
         .cancelBtn{
             border: 1px solid #ff9933;
             width: 50%;
             border-radius: 5px;
             text-align: center;
             padding: 8px;
             font-size: 16px;
             font-weight: 500;
             margin-right: 10px;
         }
         .Needbtn{
             width: 50%;
             border-radius: 5px;
             text-align: center;
             background:#ff9933 ;
             padding: 8px;
             font-size: 16px;
             color: azure;
             font-weight: 500;
     
         }
     }
     @media only screen and (max-width: 320px){
         .successOrder-ProductsBTN{
             width: 100%;
     }
     
 }
 @media only screen and (min-width: 320px){
     .successOrder-ProductsBTN{
         width: 100%;
 }
 
 }
 @media only screen and (min-width:468px){
     .successOrder-ProductsBTN{
         width: 100%;
 }
 
 }
 @media only screen and (min-width: 468px){
     .successOrder-ProductsBTN{
         width: 50%;
 }
 
 }
 }


.billingDetails{
    border: 1px solid #cecede;
    margin-top: 10px;
    border-radius: 5px;
    .billing{
      margin: 10px;
      .billingHead{
        font-size: $s-size0;
        font-weight: 700;
      }
      .billingAdd{
        font-size: 14px;
        
      }
    }
}

