    .searchDetail {
        // margin-top: 3px;
        background: #fff;
        border: 1px solid #E0E0E0;
        height: 382px;
        width: 650px;
        overflow-y: scroll;
        padding: 10px;
        position: absolute;
        box-shadow: 0 1px 4px rgba(0, 0, 0, .12);
        border-radius: 5px;

        .searchbar-product-list {
            margin-bottom: 10px;
            display: flex;
            cursor: pointer;
            align-items: center;
            color: $black;


            img {
                width: 50px;
                height: 50px;
                margin-right: 10px;
            }
            .search-title-products{
                font-size: 14px;
            }
        }
    }

    .searchDetail::-webkit-scrollbar {
        width: 3px;
        height: 3px;
    }

    .searchDetail::-webkit-scrollbar-thumb {
        border-radius: 10px;
        -webkit-box-shadow: inset 0 0 15px #c90101;
    }

    @media screen and (max-width: 1024px) {}

    @media screen and (max-width: 991px) {
        .searchDetail {
            width: 100%;
            height: auto;
            z-index: 9999;
            border: none;
            margin-top: unset;
            box-shadow: none;

            .searchbar-product-list {
                padding-bottom: 5px;
                padding-top: 5px;
                border-bottom: 1px solid #eee;

                img {
                    width: 35px;
                    height: 35px;
                }
            }

            .searchDetailList {

                ul {
                    height: 400px;
                    overflow-y: scroll;
                }
            }
        }
    }