
.orderAddressCol{
  
    width: 100%;

    padding-top: 30px;

    .orderAddressImg{
       margin: 20px 0px;
       display: flex;
    
       
        .image{
            width: 30px;
            padding-top: 5px;
           margin-right: 10px;
        }
        .details{
            .add{
                font-size: 12px;
            }
        }
    }
    
  
}