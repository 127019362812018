.ForgotPassword-mian-login{
    display: flex;
    justify-content: center;
    flex-direction: row;
    .ForgotPassword-main{
        display: flex;
        justify-content: center;
        border: 1px solid lightgray;
        // text-align: center
    width: 400px;
        flex-direction: column;
        // align-items: center;
        margin: 10px;
        padding: 20px;
        .ForgotPassword-status{
            font-size: 14px;
            margin: 20px 0px;
            font-weight: 600;
        }
        .ForgotPassword-inputbox{
            border: none;
            outline: none;
            border-bottom: 1px solid lightgray;
            width: 100% !important;
            height: 30px;
        }
        .ForgotPassword-inputbox-status{
            font-size: 12px;
            font-weight: 600;
        }
        .ForgotPassword-title{
            font-size: 24px;
            font-weight: 600;
            padding: 5px 0px;
        }
        .forget-pasword-resend-otp{
            display: flex;
            justify-content: center;
            padding: 5px 0px;
            font-size: 14px;
            font-weight: 600;
            cursor: pointer;
        }
        .forget-pasword-resend-otp:hover{
            color: $primary_color;
        }
        .forget-password-need-help{
            padding: 5px 0px;
            font-size: 14px;
            font-weight: 500;
            cursor: pointer;
        }
        .forget-password-need-help-title{
            padding-left: 3px;
        }
        .forget-password-need-help:hover{
            color: $primary_color;
        }
        .forget-password-need-help-content{
            font-size: 14px;
            font-weight: 600;
            padding-top: 10px;
        }
    }
}
