.question {
  background: $white;
  border: 1px solid #eae6eb;
  margin-bottom: 1rem;
  // box-shadow: 0 5px 15px rgba(0, 0, 0, 0.1);

  .custom-checkout-accordionheader {
    text-transform: uppercase;
    color: #878787;
    font-size: $s-size;
    font-weight: 500;
    justify-content: space-between;
    display: flex;
    padding: 5px 20px;
    border-radius: 2px 2px 0 0;

    .accordion-deliver-topright {
      border: 2px solid $primary_color;
      border-radius: 24px;
      color: $black;
      font-size: 14px;
      font-weight: 500;
      text-align: center;
      cursor: pointer;
      padding: 1px;
      width: 16%;
    }


    h4 {
      text-transform: none;
  
    }



    .question-title {
      margin-top: 4px;
    }
  }

  .accordionheader-active {
    background: $primary_color;
    color: $white;
  }

  .custom-checkout-accordionbody {
    .accordion-address-body-listitem {
      border-bottom: 1px solid #f0f0f0;
      color: black;
      margin-bottom: 10px;
      cursor: pointer;
      margin-top: 0.5rem;
      padding: 10px 20px;

      .form-check-label {
        font-weight: 500;
      }

      .checkout-list-address {
        margin-left: 25px;
       
        font-size: 14px;
        -webkit-box-orient: vertical;
        display: -webkit-box;
        max-width: 100%;
        overflow: hidden;
        -webkit-line-clamp: 3;
        text-overflow: ellipsis;

        span {
          font-weight: 500;
        }
      }

      .deliver-address-btn {
        margin-left: 25px;

        .product-detail-button {
          border-radius: 4px;
          margin-top: 10px;
          width: 25%;
        }

      }

    }

  }

  .accrodion-deliver-address-group {
    display: flex;
    width: 100%;
    padding: 14px 24px;

    .accordion-deliver-viewmore {
      border: 2px solid $primary_color;
      border-radius: 5px;
      color: black;
      font-size: $s-size;
      font-weight: 700;
      text-align: center;
      margin-right: 30px;
      cursor: pointer;
      padding: 4px;
      width: 35%;
    }

  }

  .checkout-paymentmethod-address-form {
    padding: 0px 20px 0px 0px;

    @media only screen and (max-width: 991px) {
      padding: 10px 20px 0px 20px;
    }
  }

  .checkout-address-form {
    margin-bottom: 10px;
    margin-top: 0.5rem;
    padding: 10px 25px;

    .checkout-address-form-title {
      color: $primary_color;
      font-weight: 500;
    }

    .address-form {
      // margin-top: 20px;

      .address-sav-btn {
        margin-left: unset;

        .product-detail-button {
          margin-top: unset;
        }

        .btn2 {
          background: none;
          border: 1px solid $primary_color;
          color: $black;
        }
      }
    }

  }



}


.order-summary-btn {
  margin-left: unset;
  justify-content: flex-end;

  .product-detail-button {

    margin-bottom: 11px;
    margin-right: unset;
    margin-top: unset;
  }
}

.checkout-payment-method {
  color: black;
  margin-bottom: 10px;
  cursor: pointer;
  margin-top: 0.5rem;
  padding: 5px 0px 0px 16px;

  .form-check {
    margin-bottom: 10px;
  }
}

.checkout-cart-paymentmethod {
  .payment_method {
    position: sticky;
    top: 50px;
  }

}

.contactAddressButton {
  padding: 10px 10px;
  border: 1px solid #eee;
  border-radius: 5px;
  font-weight: 700;
  cursor: pointer;
}

.full-address-div {
  border: 1px solid #eee;
  padding: 10px 20px;
  margin-bottom: 15px;

  .product-detail-buttongroup {
    margin-left: unset;
  }
}

.delivery-options {
  display: flex;
  text-align: center;
  justify-content: center;

  .delivery_options_tab {
    cursor: pointer;
    border: 1px solid #eee;
    padding: 10px 50px 10px 50px;
    margin: 5px 7px 5px 7px;
  }
}

.active_delivery_point {
  border: 2px solid $primary_color !important;
}

.active_delivery_point_text {
  color: $primary_color;
  font-weight: 500;
}

.active_delivery_point_icon {
  color: $primary_color;
}


// .......................auticomplete........................

.autocomplete-inputbox-main {
  position: relative !important;
}

.autocomplete-inputbox {
  width: 100%;
  border: none !important;
  outline: none;
  padding: 20px 40px;
  border-radius: 5px;
  border: 1px solid $border_color !important;
}

.autocomplete-dropdown-container {
  position: absolute;
  width: 100% !important;
  z-index: 1;
  background: $white;
  border: 1px solid $border_color;
  cursor: pointer;
  padding: 0px 10px;
}

.autocomplete-inputbox-searchbox {
  position: absolute;
  left: 10px;
  top: 27%;
  font-size: 21px;
}

.autocomplete-inputbox-wrong {
  position: absolute;
  right: 10px;
  top: 27%;
  font-size: 21px;
}

.fjdfdjh {
  font-size: 14px;
  font-weight: 500;
  font-family: $font-family-2;
  padding: 3px 0px;

}

.fjdfdjhgfh {
  font-size: 12px;

  font-family: $font-family-2;
  // padding: 5px 0px;
  color: gray;
}