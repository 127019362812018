// 
// SIZES ---------
// 

$nav-height: 6rem;

$xs-size: 12px;
$s-size: 14px;
$s-size0: 16px;
$m-size: 20px;
$l-size: 24px;
$xl-size: 4.8rem;
$xxl-size: 5.6rem;

$top: 10rem;
$top-mobile: 8.5rem;
$bottom: 15rem;
$line-height: 2.4rem;

$pad-desktop: 10rem;


$font-family-primary: Poppins;
$font-family-2 :Roboto;