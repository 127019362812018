.progressbar {
    width: 220px;
    height: 18px;
    display: flex;
    flex-direction: row;
    justify-content: flex-start;
    align-items: stretch;
    border-radius: 3px;
    background-color: $border_color;
    overflow: hidden;
    margin-bottom: 15px;
}

.bar {
    /* Size */
    width: 0%;

    /* Style */
    background: rgb(255, 174, 105);
    background: linear-gradient(
        180deg,
        rgba(255, 174, 105, 1) 0%,
        rgba(255, 173, 102, 1) 32%,
        rgba(255, 132, 25, 1) 100%
    );
}

.progressbar-container {
    display: flex;
    flex-direction: row;
}

.progressbar-text-container {
    width: 30px;
    margin-left: 15px;
    display: flex;
    flex-direction: row;
    font-weight: 400;
    color: $primary_color;
}
.progressbar-startext {
    width:55px;
    display: flex;
    font-weight: 400;
    color: $primary_color;
    font-size: 14px;
}