.nodata{
    display: flex;
   
    flex-direction: column;
    align-items: center;

margin-top: 150px;
    margin-bottom: 20px;
    height: 100vh;
    .nodata-sub{
     
        .nodata-sub-image{
          width: 200px;
          height: 200px;
        }
    }
    .nodata-sub-data-msg{
        font-size: 20px;
        font-weight: 700;
    }
    .nodata-sub-data{
        font-size: 14px;
        font-weight: 500;
        display: flex;
        text-align: center;
        padding: 8px 0px;
    }
    .nodata-goback-button{
         color: $white;
         background: $primary_color;
         padding: 5px 15px;
         border-radius: 5px;
         border: none;
         font-size: 14px;
         cursor: pointer;
         .nodata-goback-button-arrow{
            font-size: 20px;
         }
    }
}