.OrderStatus {
    padding-top: 10px;
    padding-left: 10px;
    padding-right: unset;
    .Order-filter-header{
        margin-bottom: 20px;
        font-weight: 500;
    }

    .Order-filter-title{
        margin-bottom: 10px;
        font-size: $s-size;
    }

    ul {
        list-style-type: none;

        padding: unset;

        li {
            font-size: 14px;
            padding: 5px 0px;

            label {
                padding-left: 10px;

                color: $paragraph-color;
            }
        }
    }
}



.orderlist {


    box-sizing: border-box;

    padding-top: 10px !important;
    padding-right: 10px !important;
    padding-left: 10px;
    margin: unset;

    .items {
        padding-top: unset;
        padding: 16px;
        margin-bottom: 5px;
        border: 1px solid #dbdbdb;

        background-color: #fff;

        :hover {
            box-shadow: 0 0 0 0 rgba(0, 0, 0, 15);

        }

        .item {

            h6 {
                font-size: 14px;
            }

            .contant {
                padding: 0px 12px;

            }

            .arrowButton {

                display: flex;
                justify-content: center;
                padding-left: unset;
                padding-right: unset;
                margin-top: 10px;

                .arr {

                    margin-top: 10px;
                    font-size: 20px;

                }
            }

            .sub {
                font-size: 14px;
            }

            .delivered {
                display: flex;

                .dot {
                    height: 8px;
                    width: 8px;
                    background-color: green;
                    border-radius: 100%;
                    margin-top: 5px;
                    margin-right: 5px;

                }
            }


            .images {
                display: flex;
                justify-content: center;

                img {
                    width: 100%;
                    min-width: 70px;
                    object-fit: contain;
                    height: 60px;
                    display: block;
                    margin-left: auto;
                    margin-right: auto;


                }

            }

            .arrow {
                display: flex;
                justify-content: end;
                padding-top: 20px;
            }
        }
    }
}

.orderRow {
    padding: unset;

    .filterCol {
        padding-left: unset;
    }

    .fliterbtn {
        float: right;
        padding: 10px 10px;
        display: flex;
        align-items: end;
        .filter-icon {

        }


    }



}

@media only screen and (max-width: 320px) {


    .sub {
        overflow: hidden;
        display: -webkit-box;
        -webkit-line-clamp: 1;
        -webkit-box-orient: vertical;
    }

}

@media only screen and (min-width: 320px) {


    .sub {
        overflow: hidden;
        display: -webkit-box;
        -webkit-line-clamp: 1;
        -webkit-box-orient: vertical;
    }


}

@media only screen and (min-width: 575px) {


    .sub {
        overflow: hidden;
        display: -webkit-box;
        -webkit-line-clamp: 1;
        -webkit-box-orient: vertical;
    }

}

@media only screen and (min-width: 768px) {

    .sub {
        overflow: hidden;
        display: -webkit-box;
        -webkit-line-clamp: 1;
        -webkit-box-orient: vertical;
    }


}

@media only screen and (max-width: 991px) {


    .sub {
        overflow: hidden;
        display: -webkit-box;
        -webkit-line-clamp: 1;
        -webkit-box-orient: vertical;
    }


}

.Sheet {
    height: 70%;
}

.btns {
    display: flex;
    justify-content: space-evenly;
    padding: 0px 10px 10px 10px;

    .button2 {
        border-radius: 5px;
        width: 100%;
        text-align: center;
        padding: 8px;
        font-size: 17px;
        color: $white;
        background: $primary_color;
    }

    .button1 {
        border-radius: 5px;
        border: 1px solid $primary_color;
        width: 100%;
        padding: 8px;
        font-size: 17px;
        text-align: center;
        margin-right: 9px;
    }

}