.about-main{
    position: relative; 
    font-family: $font-family-2;
    .about-content{
        position: absolute;
        background: #fff;
        top:80px;
         
        width: 30%;
        right: 5%;
        padding: 20px;
        line-height: 2.2;
    }

    .about-button{
        background:$primary_color;
        color: #fff;
        padding: 10px 40px;
        width: fit-content;
        font-size: 18px;
        margin-top: 10px;
    }
}
@media screen and (max-width: 991px){
    .about-main{
        position: unset; 
        font-family: $font-family-2;
        .about-content{
            position: unset;
            background: #fff;
            top:none;
             
            width: 100%;
            right: none;
            padding: 10px;
            line-height:unset;
        }
    
        .about-button{
            background:$primary_color;
            color: #fff;
            padding: 10px 40px;
            width: fit-content;
            font-size: 18px;

            margin: 10px 0px 30px 0px;
        }
    }
}

// .map-area{
//     overflow: hidden;
//     position: relative;
//     padding-top: 10px;
// }
// .map-area{
//     position: absolute;
//     border: 0;
//     height: 100%;
//     // width: 100%;
//     left: 0;
//     top: 0;
// }

// .map-area {
//     overflow: hidden;
//     position: relative;
//     padding-top: 10px;
// }

// /* Style for the child iframe with the same .map-area class */
// .map-area iframe {
//     position: absolute;
//     border: 0;
//     height: 100%;
//     /* width: 100%; */
//     left: 0;
//     top: 0;
// }

.address-map{
    width: 100%;
    height: 600px;
}

@media screen and (max-width:576px){
    .address-map{
        width: 100%;
        height: 300px;
    }
}



// ................................BANNER8............................

.brands-col-maindivs {
    width: 100%;

    padding: 10px 10px !important;

    .single-banners {
        position: relative;
        cursor: pointer;
        width: 100%;
        .brand-imagess {
            width: 100%;
            object-fit: cover;
        }
    }

}
.brand-title{
    font-size: 22px;
    font-family: Roboto;
    font-weight: 500;
    display: flex;
    justify-content: center;
    padding-top: 5px;
}
.brand32-main {
    display: flex;
}

.dfdbhgh {
    border: 1px solid black;
}


.single-banners::before {
    border-top: 2px solid #fff;
    // border-bottom: 2px solid #fff;
    // border-right: 2px solid #fff;

    content: "";
    left: 15px;
    opacity: 0;
    right: 100%;
    position: absolute;
    top: 15px;
    // -webkit-transition: all 600ms ease 0s;
    // transition: all 600ms ease 0s;
    // z-index: 1;
}

.single-banners::before {
    // border-top: 2px solid #fff;
    border-bottom: 2px solid #fff;
    // border-right: 2px solid #fff;

    content: "";
    left: 15px;
    opacity: 0;
    right: 100%;
    position: absolute;
    bottom: 18px;
    -webkit-transition: all 600ms ease 0s;
    transition: all 600ms ease 0s;
    // z-index: 1;
}

.single-banners:hover:before {
    opacity: 1;
    right: 15px;
}

.single-banners::after {
    border-right: 2px solid #fff;
    bottom: 19px;
    content: "";
    left: 15px;
    opacity: 0;
    position: absolute;
    // top: 15px;
    -webkit-transition: all 600ms ease 0s;
    transition: all 600ms ease 0s;
    -webkit-transition: all 0.5s ease-out;
}

.single-banners::after {
    border-left: 2px solid #fff;

    content: "";
    right: 15px;
    opacity: 0;
    position: absolute;
    top: 100%;
    -webkit-transition: all 600ms ease 0s;
    transition: all 600ms ease 0s;
    // -webkit-transition: all 0.5s ease-out;
}

.single-banners:hover:after {
    opacity: 1;
    top: 15px;
}

.special-collection{
    display: flex;
    justify-content: center;
    text-align: center;
    font-size: 28px;
    font-family: $font-family-2;
    font-weight: 600;
}