.footerSecondContainer{
    background:$nav-bg;
    color: $nav-text-color;
    padding-top: 50px;
    .apple_icon{
        width:100%;
    }
    .footerLogoimage{
        max-height: 40px;
        object-fit: contain;
    }
    .footer_descip{
        font-size: $s-size;
        padding-right: 10px;
        padding-top: 5%;
        font-weight:400;
    }
    .footerHeading{
        font-weight: 700;
        font-size: $m-size;
      
        padding-bottom: 4%;
    }

    .facebook{
        width: 12.85px;
        height: 24px;
        padding-right: 15.5px;
    }
    .massages{
        font-weight: 700;
        font-size: 14px;
    
        font-style: normal;
       
    }
  
    .contact{
        font-weight: 400;
        font-size: $s-size;

        //padding-left: 5%;
    }

     .footer_component{
        float: right;
        //padding-top: 3%;
    }
    .appimg{
        display: flex;
        gap: 16px;
        width: 320px;
        height: 64px;        

    }
    
    .middle_paragraph{
        font-size: $s-size;
        // gap: 10px;
        border-top: 1px solid #ffffff66;
        border-bottom: 1px solid #ffffff66;
        padding: 0px 0px;
        display: flex;
            flex-direction:row;
            
        
    }
    .dddddd{
        padding: 18px 10px 10px 0px;
        text-decoration: none;
        font-weight: 400;
        font-size: $s-size;
            color: $nav-text-color;
            display: flex;
            .footer-line{
                padding:  0px 10px;
            }
    }
    .dddddd:first-child{
        padding: 18px 10px 10px 0px;
    }
    .ourshop_com{
        font-style: normal;
        font-weight: 400;
        font-size: $xs-size;
        color: rgba(255, 255, 255, 0.6);
        //padding-left: 75%;
    }
    .secondfooterHeading{
        font-weight: 700;
        font-size: $m-size;

    }
    .Connectwithus{
        font-weight: 700;
        font-size: $m-size;
        //padding-top: 5%;
    }
    .Reachout{
        font-size: $s-size;
        font-weight: 400;
        margin-top: -4%;
    }
    
     @media screen and (max-width: 2560px){
        
        .wearealways{
            font-weight: 700;
            font-size: 20px;
            padding-bottom: 4%;
           // padding-top: 5%;
        }
      
        
    } 

    @media only screen and (max-width: 991px){
        .maxWidthContainerFluid{
            max-width: 960px;
        }
            padding-top: 20px ;
        .sosalmidiaimg{
            padding-bottom: 25px;
        }
        .footerHeading{
                padding-bottom: 0%;
        }
    }
    @media screen and (max-width: 1440px){
        
        /* .secondfooterHeading {
            padding-top: 9%;
        } */
    }
    @media screen and (max-width: 1326px){
        
        .wearealways{
            padding-top: 2%;
        }

    }
    @media screen and (max-width: 1024px){

        .middle_paragraph {
            a{
                padding:0px 11px;
            }
            a:first-child{
                padding-left: 0px;
                padding-right: 11px;
            }
            a:last-child{
                padding-left: 11px;
                padding-right: 0px;
            }
        }
       
        .wearealways{
            padding-top: 0%;
        }
        
    }
     
}
.policies-main{
    margin: 5%;
    font-size: 15px;
}
.policies-titles{
    padding-bottom: 4%;
}

.fotter-contact{
    border-top: 1px solid $white;
    border-bottom: 1px solid $white;
}
.footer-text-contant{
    padding: 5px 0px;
    font-size: 12px;
}