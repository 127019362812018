/* Body */
$body-background: linear-gradient(to left, #a55eea, #45aaf2);
$radius : 20px;
$table-padding : 20px;
$break-small: 320px;
$break-large: 1200px;

$primary: #4b7bec;
$echo: #f7b731;
$pro: #26de81;
$business: #a55eea;

@import '../1\ -\ settings/colors';

@mixin transition($time, $property) {
    -webkit-transition: $time $property ease;
    -ms-transition: $time $property ease;
    transition: $time $property ease;
}

/* Card Themes - Start */

.basic {
    .pricing-header {
        background-color: $primary_color;
    }

    .price-circle {
        border: 10px solid $primary_color;
        transition: all .4s;
    }

    &:hover {
        .price-circle {
            border-width: 5px;
        }
    }

    .buy-now {
        &:hover {
            background-image: none !important;
            background-color: $primary_color !important;
            box-shadow: 1px 3px 5px rgba(0, 0, 0, .4);
        }
    }
}

.echo {
    .pricing-header {
        background-color: $echo;
    }

    .price-circle {
        border: 10px solid $echo;
        transition: all .4s;
    }

    &:hover {
        .price-circle {
            border-width: 5px;
        }
    }

    .buy-now {
        &:hover {
            background-image: none !important;
            background-color: $echo !important;
            box-shadow: 1px 3px 5px rgba(0, 0, 0, .4);
        }
    }
}

.pro {
    .pricing-header {
        background-color: $pro;
    }

    .price-circle {
        border: 10px solid $pro;
        transition: all .4s;
    }

    &:hover {
        .price-circle {
            border-width: 5px;
        }
    }

    .buy-now {
        &:hover {
            background-image: none !important;
            background-color: $pro !important;
            box-shadow: 1px 3px 5px rgba(0, 0, 0, .4);
        }
    }
}

.business {
    .pricing-header {
        background-color: $business;
    }

    .price-circle {
        border: 10px solid $business;
        transition: all .4s;
    }

    &:hover {
        .price-circle {
            border-width: 5px;
        }
    }

    .buy-now {
        &:hover {
            background-image: none !important;
            background-color: $business !important;
            box-shadow: 1px 3px 5px rgba(0, 0, 0, .4);
        }
    }
}

/* Card Themes - End */



.containerq {
    max-width: 1200px;
    height: auto;
    margin: auto;
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
    text-align: center;
}

.column {
    flex: 25%;
    padding: 10px;
    width: calc(33.3% - 30px);
    box-sizing: border-box;

    @media screen and (max-width: 980px) {
        flex: 50%;
        display: block;
        padding:10px 20px 10px 20px
    }

    @media screen and (max-width: 700px) {
        flex: 100%;
        display: block;
    }
}

.pricing-card {
    @include transition(0.4s, background-color);
    height: 100%;
    background-color: white;
    border: 1px solid #eee;
    border-radius: 5px;
    position: relative;
    cursor: pointer;
    transition: all .4s;

    @media only screen and (min-width: 991px) {
        &:hover {
            box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.4);
            transform: scale(1.05);
        }

    }


    .popular {
        position: absolute;
        top: 0;
        right: 5%;
        width: auto;
        padding: 10px;
        border-bottom-left-radius: $radius;
        border-bottom-right-radius: $radius;
        background-color: #eb3b5a;
        color: white;
        font-size: 12px;
        z-index: 1;
        box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.2);
    }

    .badge-box {
        padding: 0 40px;
        margin-top: 70px;

        span {
            display: inline-block;
            border: 1px solid $primary_color;
            padding: 4px 12px;
            border-radius: 25px;
            overflow: hidden;
            color:$primary_color;
        }
    }

    .pricing-header {
        width: 100%;
        height: 150px;
        position: relative;
        border-radius: 5px;
        /*border-bottom: 20px solid $primary;*/
        /*
    border-radius: $radius $radius 150px 150px;
    -webkit-border-radius: $radius $radius 150px 150px;
    -moz-border-radius: $radius $radius 150px 150px;
    */

        .plan-title {
            font-size: 24px;
            color: white;
            position: relative;
            top: 25%;
        }

        .price-circle {
            width: calc(33.3% - 30px);
            width: 120px;
            height: 120px;
            border-radius: 100%;
            left: calc(50% - 60px);
            top: 60%;
            background-color: white;
            position: absolute;

            //box-shadow: 1px 3px 5px rgba(0, 0, 0, .4);
            .info {
                display: block;
                font-size: 12px;
                font-weight: bold;
                color: gray;
            }

            .price-title {
                display: block;
                font-size: 28px;
                padding: 28px 0 0;
                font-weight: bold;

                small {
                    font-size: 18px;
                }
            }
        }

        h2 {
            position: relative;
            top: 40%;
            color: #fff;
        }
    }

    ul {
        margin: 10px 0 0 0;
        padding: 0;

        li {
            list-style-type: none;
            display: block;
            padding: 15px 0 15px 0;
            margin: 0;
            border-bottom: 1px solid #f2f2f2;
        }
    }

    .buy-button-box {
        width: 100%;
        float: left;
        margin-top: 20px;
        margin-bottom: 20px;
        .buy-now {
            text-decoration: none;
            color: white;
            padding: 10px 30px;
            border-radius: 5px;
            background:$primary_color;
            margin-top: 20px;
            
        }
    }
}



.swiper-subscription {
    width: 300px;
    height: auto;

    .swiper-slide-shadow {
        background: none;
    }
}

// .swiper-slide {
//     display: flex;
//     align-items: center;
//     justify-content: center;
// }

.containerq-mobile {
    height: 100%;
    position: relative;
    overflow: hidden;
}

.subscription-modal{
    height: 520px;
}

.modal-subscription{
    .pricing-card{
        height: 400px;
        .pricing-header{
            height: 120px;
            .plan-title{
                font-size: 20px;
            }
            .price-circle {
               width: 100px;
               height: 100px;
        
        .price-title{
            padding: 10px 0px 0px;
            font-size: 20px;
        }
        
    
  
    }
    }
    ul{
        li{
            padding: 7px 0px 7px 0px;
        }
    }
    }

}

.subscription-cancel{
    display: flex;
    justify-content: end;
    .subscription-cancel-icon{
        width: 15px;
        height: 15px;

    }
}